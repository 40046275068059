/* eslint-disable indent */
(function () {
	angular
		.module('fca.calculator')
		.component('fcaCalculator', {
			controller: FcaCalculator,
			controllerAs: '$calcCtrl',
			templateUrl: '/calculator/calculator.html',
			bindings: {
				acode: '<',
				acTax: '<',
				adminFee: '<',
				affiliateLogoSource: '@',
				affiliateId: '@',
				altFinance: '<',
				altLease: '<',
				applyForFinancingIncentivesTotal: '<',
				basePrice: '<',
				brandCode: '<',
				buildAndPriceApplyForFinancingDiscountTotalAfterTax: '=',
				buildAndPriceApplyForFinancingDiscountTotalBeforeTax: '=',
				calculatorDefaultFinanceRate: '<',
				calculatorSliderStepSize: '<',
				cash: '<',
				dealerCode: '@',
				dealerPrice: '<',
				incentivizedPrice: '<',
				defaultFinanceTerm: '<',
				defaultLeaseTerm: '<',
				defaultPaymentFrequency: '<',

				defaultMileage:'@',
				defaultMileageLegacy:'@',

				disclaimerBasePrice: '@',
				disclaimerDealerAdminFees: '@',
				disclaimerFinancing: '@',
				disclaimerLeasing: '@',
				disclaimers: '<',
				discountAfterTaxTotal: '<',
				discountBeforeTaxTotal: '<',
				downPayment: '<',
				eirProgramDisabled: '<',
				finance: '<',
				financeFrequencyOverride: '<',
				financeMode: '<',
				financeTermOverride: '@',
				freight: '<',
				gkrp: '<',
				greenLevy: '<',
				hideApplyForFinance: '<',
				incentives: '<',
				isApplyForFinancingMode: '<',
				isOverlay: '<',
				isTradeIn: '<',
				lease: '<',
				location: '<',
				luxuryTax: '<',
				modelYearId: '<',
				module: '@',
				nameplateCode: '<',
				options: '<',
				packageAndOptionsCode: '<',
				paymentUpdateCallback: '<',
				provinceCode: '<',
				scratchSave: '<',
				selectedBrandCode:'<',
				selfHook: '<',
				showCtaContactDealer: '<',
				showCtaFinancing: '<',
				showCtaReturnToDetails: '<',
				showIsDigital: '<',
				showLeaseTab: '<',
				showPaymentFrequency: '<',
				showPaymentSummary: '<',
				showSalesTax: '<',
				showSeeOptions: '<',
				sniApplyForFinanceCta: '@',
				tradeOwed: '<',
				tradeValue: '<',
				trimEn: '<',
				trimFr: '<',
				trimName: '<',
				useBestRate: '<',
				vin: '@',
				year: '<'
			}
		});

	function initEmptyField($calcCtrl) {
		//Ugly fix but necessary for now : https://issues.nurun.com/browse/QFGBFI-319
		if ($calcCtrl.downPayment === '' || $calcCtrl.downPayment === null) {
			$calcCtrl.downPayment = 0;
		}

		if ($calcCtrl.tradeValue === '' || $calcCtrl.tradeValue === null) {
			$calcCtrl.tradeValue = 0;
		}

		if ($calcCtrl.tradeOwed === '' || $calcCtrl.tradeOwed === null) {
			$calcCtrl.tradeOwed = 0;
		}
	}

	function FcaCalculator(
		$scope,
		$rootScope,
		$location,
		$http,
		$window,
		calculatorService,
		configService,
		fcaGeolocator,
		financeProgramService,
		$exceptionHandler,
		$timeout,
		$translate) {

		'ngInject';

		let $calcCtrl = this;
		const cashType = 'Cash';
		const financeType = 'Finance';
		// yes, we detect safari
		const IS_SAFARI = navigator.vendor && navigator.vendor.indexOf('Apple') > -1;


		$calcCtrl.affiliateMode = 'public';

		$calcCtrl.downPaymentCpt = 0;
		$calcCtrl.tradeValueCpt = 0;
		$calcCtrl.tradeOwedTriggered = false;

		$calcCtrl.language = FCA_SITES_CONFIG.language;
		if ($calcCtrl.language == 'fr') {
			$calcCtrl.contactADealerUrl = "contacter-un-concessionnaire";
		} else {
			$calcCtrl.contactADealerUrl = "contact-a-dealer";
		}

		$calcCtrl.paymentFrequencies = FCA_SITES_CONFIG.calculatorPaymentFrequencies;

		// default finance and lease terms (should be used during initialization)
		$calcCtrl.defaultFinanceTerm = null;

		$calcCtrl.defaultLeaseTerm = null;

		/**
		 * currently selected finance program
		 */
		$calcCtrl.financeProgram = null;

		/**
		 * currently selected lease program
		 */
		$calcCtrl.leaseProgram = null;

		/**
		 * Dealer deltas
		 *
		 * They are currently not shown to the end user
		 *
		 * - can be positive (fee)
		 * - can be negative (discount)
		 */
		$calcCtrl.cashDealerDelta = null;
		$calcCtrl.financeDealerDelta = null;
		$calcCtrl.leaseDealerDelta = null;

		$calcCtrl.previousTradeInValue = 0;
		$calcCtrl.firstTradeInValue = false;

		$calcCtrl.maxFinanceAdjustment = 1000000; // this must be large enough to accomodate any value of the adjustment passed by bindings

		$calcCtrl.$onInit = () => {
			// hotfix SNI : dealerPrice override the others netAmount and we don't want this anymore.
			$calcCtrl.dealerPrice = 0;

			// initialize the cta financing when the information doesn't come from calculator.ftl
			if (!$calcCtrl.showCtaFinancing) {
				$calcCtrl.showCtaFinancing = true;
			}
			// attempt to initialize the province code from the location parameter
			if (!$calcCtrl.provinceCode) {
				$calcCtrl.provinceCode = $calcCtrl.location ? $calcCtrl.location.province : 'ON';
			}

			// dealer delta for the current payment mode
			$calcCtrl.dealerDelta = 0;

			$calcCtrl.useBestRate = false;

			$calcCtrl.isAlfaRomeo = FCA_SITES_CONFIG.name === 'alfaromeo';
			$calcCtrl.modelYearId = FCA_SITES_CONFIG.modelYearIds[0];
			$calcCtrl.hashParameters = getHashParameters();
			if ($calcCtrl.hashParameters.financeTerm) {
				$calcCtrl.defaultFinanceTerm = $calcCtrl.hashParameters.financeTerm;
			}

			if ($calcCtrl.hashParameters.leaseTerm) {
				$calcCtrl.defaultLeaseTerm = $calcCtrl.hashParameters.leaseTerm;
			}

			// honour the active tab parameter, if present
			$calcCtrl.activeTab = $calcCtrl.hashParameters.activeTab ? $calcCtrl.hashParameters.activeTab : 'cash';

			if ($calcCtrl.isApplyForFinancingMode) {
				$calcCtrl.activeTab = 'finance';
				$calcCtrl.showPaymentFrequency = false;
			}

			// honour the initialization parameter, if present
			if ($calcCtrl.financeMode) {
				$calcCtrl.activeTab = $calcCtrl.financeMode;
			}

			// We're saving the logo URL so it can be re-used in the apply for financing calculator without
			// having to inject from another FTL.
			storeAffiliateId();
			if ($calcCtrl.affiliateLogoSource) {
				window.FcaCookieChecker.addSessionStorage('affiliateLogoSource', $calcCtrl.affiliateLogoSource);
			} else {
				$calcCtrl.affiliateLogoSource = sessionStorage.getItem('affiliateLogoSource')
			}
			$calcCtrl.affiliateId = getStoredAffiliateId();
			$calcCtrl.inIframeMode = $location.search()['view-name'] === 'headless_renderer';

			$calcCtrl.siteGroupCode = FCA_SITES_CONFIG.siteGroupCode;
			let brandCode = $calcCtrl.selectedBrandCode || ($calcCtrl.brandCode || window.FCA_SITES_CONFIG.name);
			$calcCtrl.brandSite = window.FCA_SITES_CONFIG.brandSiteUrls[brandCode];

			/**
			 * UI VARIABLES
			 */
			// Accordion state variables
			$calcCtrl.expandParameters = false;
			$calcCtrl.expandFinance = false;
			$calcCtrl.expandDiscountBefore = false;
			$calcCtrl.expandDiscountAfter = false;
			$calcCtrl.expandTaxes = false;
			$calcCtrl.showRateSwitch = false;
			// Finance and Lease adjustment
			$calcCtrl.initialDownPayment = $calcCtrl.downPayment;
			// replace the down payment with zero if it's not initialized by a binding
			$calcCtrl.downPayment = $calcCtrl.downPayment ? $calcCtrl.downPayment : 0;
			$calcCtrl.previousDownPayment = $calcCtrl.downPayment;
			$calcCtrl.firstDownPaymentInput = true; // this is only true until the first interaction from the user
			$calcCtrl.tradeValue = ($calcCtrl.tradeValue || 0);
			$calcCtrl.previousTradeInValue = $calcCtrl.tradeValue;
			$calcCtrl.tradeOwed = ($calcCtrl.tradeOwed || 0);
			$calcCtrl.previousTradeOwed = $calcCtrl.tradeOwed;
			$calcCtrl.tradeOwedMax = 0;
			$calcCtrl.trueTradeOwed = 0;
			$calcCtrl.annualMileageChoice = 0;
			// Finance adjustment total
			$calcCtrl.financeAdjustments = 0;

			$calcCtrl.discountBeforeTax = 0;
			$calcCtrl.discountAfterTax = 0;

			// cash values
			$calcCtrl.cashTaxes = 0;
			$calcCtrl.cashDiscountTotalBeforeTaxes = 0;
			$calcCtrl.cashDiscountTotalAfterTaxes = 0;
			// finance values
			$calcCtrl.paymentFinance = 0;
			$calcCtrl.paymentFinanceWithTax = 0;
			$calcCtrl.financeDiscountTotalBeforeTax = 0;
			if ($calcCtrl.buildAndPriceApplyForFinancingDiscountTotalBeforeTax) {
				$calcCtrl.financeDiscountTotalBeforeTax = $calcCtrl.buildAndPriceApplyForFinancingDiscountTotalBeforeTax;
			}
			$calcCtrl.financeDiscountTotalAfterTax = 0;
			if ($calcCtrl.buildAndPriceApplyForFinancingDiscountTotalAfterTax) {
				$calcCtrl.financeDiscountTotalAfterTax = $calcCtrl.buildAndPriceApplyForFinancingDiscountTotalAfterTax;
			}

			$calcCtrl.hasLease = false;
			$calcCtrl.hasAltLease = false;

			// lease values
			$calcCtrl.paymentLease = 0;
			$calcCtrl.paymentLeaseWithTax = 0;
			$calcCtrl.leaseDiscountTotalBeforeTax = 0;
			$calcCtrl.leaseDiscountTotalAfterTax = 0;

			// incentive total
			$calcCtrl.applyForFinancingIncentivesTotal = 0;

			// Frequency
			$calcCtrl.paymentFrequency = 0;


			// check if we can accomodate any default payment frequency
			if ($calcCtrl.defaultPaymentFrequency) {
				let index = $calcCtrl.paymentFrequencies.findIndex((paymentFrequency) => paymentFrequency.key === $calcCtrl.defaultPaymentFrequency);
				if (index !== -1) {
					$calcCtrl.paymentFrequency = index;
				}
			}

			if ($calcCtrl.financeFrequencyOverride != null) {
				$calcCtrl.paymentFrequency = $calcCtrl.financeFrequencyOverride;
			}

			/*
			 * Default value for mileage is still 20k but its possible
			 * to initialize the calculator object with another value.
			 */
			if ($calcCtrl.defaultMileage == null) {
				$calcCtrl.defaultMileage = '20000';
			}

			if ($calcCtrl.defaultMileageLegacy == null) {
				$calcCtrl.defaultMileageLegacy = '20K';
			}

			//Default mileage for Alfa
			if ($calcCtrl.isAlfaRomeo) {
				$calcCtrl.defaultMileage = '16000';
				$calcCtrl.defaultMileageLegacy = '16K';
			}

			// Term and rate
			$calcCtrl.financeTerm = 0; // index position of the finance term slider
			$calcCtrl.financeInterestRate = [];
			$calcCtrl.leaseTerm = 0; // index position of the lease term slider
			$calcCtrl.leaseInterestRate = [];
			$calcCtrl.interestRate = 0;

			// Residual
			$calcCtrl.residualList = [{
				key: '0K',
				value: {0: '0'}
			}];

			// Init programs values
			$calcCtrl.currentModelYearId = 0;
			$calcCtrl.programs = [];

			$calcCtrl.formattedPricingData = {};
			$calcCtrl.pricing = null;

			// Init sales tax values
			$calcCtrl.salesTaxList = [];
			$calcCtrl.currentTaxRate = 1.0;

			//electric discounts
			$calcCtrl.electricDiscountsList = [];
			$calcCtrl.electricDiscountObject = [];
			$calcCtrl.currentElectricProvincialAmounts= [];
			$calcCtrl.currentElectricFederalAmounts= [];
			$calcCtrl.defaultElectricPercentage = 100;
			$calcCtrl.currentElectricProvincialPercentage = $calcCtrl.defaultElectricPercentage;
			$calcCtrl.currentElectricFederalPercentage = $calcCtrl.defaultElectricPercentage;
			$calcCtrl.setElectricDiscounts(false);

			// there are some programs initialized
			if ($calcCtrl.cash
				|| $calcCtrl.lease
				|| $calcCtrl.altLease
				|| $calcCtrl.finance
				|| $calcCtrl.altFinance) {

				let pricing = {
					cash: $calcCtrl.cash,
					finance: $calcCtrl.finance,
					altFinance: $calcCtrl.altFinance,
					lease: $calcCtrl.lease,
					altLease: $calcCtrl.altLease
				};

				// set the proper name for the incentives (according to the language)
				$calcCtrl.setIncentiveNames(pricing.cash);
				$calcCtrl.setIncentiveNames(pricing.finance);
				$calcCtrl.setIncentiveNames(pricing.altFinance);
				$calcCtrl.setIncentiveNames(pricing.lease);
				$calcCtrl.setIncentiveNames(pricing.altLease);

				$calcCtrl.patchLeaseTerms(pricing); // patch the lease terms
				$calcCtrl.pricing = pricing;
				$calcCtrl.assignPricingData();
			}

			$calcCtrl.setSalesTax();
			$calcCtrl.includeSalesTax = false;

			// Slider option
			$calcCtrl.sliderStepSize = $calcCtrl.calculatorSliderStepSize;

			// CTA
			$calcCtrl.sniApplyForFinanceCta = "false";

			$scope.$$postDigest(function () {
				if ($calcCtrl.isApplyForFinancingMode) {
					$('.C_CAL_TAB-input-list').toggleClass('toggle-sliders');
				}

				if ($('.C_NID_OL-window .C_CAL').length > 0) {
					$calcCtrl.modalDialog = $('.C_NID_OL-window');

					$timeout(() => {
						$calcCtrl.setFocusTrap();
					});
				}
			});

			$scope.$on('calculator:update-calculator-parameters', $calcCtrl.onUpdateCalculatorParameters);

			$scope.$on('calculator:refresh-focus-trap', $calcCtrl.onRefreshFocusTrap);

			$scope.$on('calculator:recalculate', $calcCtrl.onCalculatorRecalculate);

			$scope.$on('calculator:set-programs', $calcCtrl.onCalculatorSetPrograms);

			$rootScope.$on('jelly:updated-jelly', $calcCtrl.onJellyUpdated);

			$rootScope.$on('navigation: payment-type-changed', $calcCtrl.onNavigationPaymentTypeChanged);

			$scope.$on('calculator:set-aff-programs', $calcCtrl.onCalculatorApplyForFinancingPrograms);

			$scope.$on(fcaGeolocator.getLocationChangedEvent(), $calcCtrl.onLocationChange);

			$rootScope.$on('calculator:apply-trade-in-value', $calcCtrl.applyTradeInValue);

			//broadcast the payment change (so that the drop-downs can update the payment type)
			$calcCtrl.broadCastPaymentChange($calcCtrl.activeTab);

			if ($calcCtrl.isApplyForFinancingMode) {
				$calcCtrl.updateCalculatorParametersLegacy();
			}

			// Forces update to the model so that it doesn't set the value to '50', the browser's default
			// https://issues.nurun.com/projects/MFBR/issues/MFBR-2589
			$scope.$watch('$calcCtrl.downPayment', () => {
				if ($calcCtrl.vin === "" && $calcCtrl.scratchSave === "") {
					$calcCtrl.downPaymentCpt++;
					if ($calcCtrl.downPaymentCpt <= 1) {
						$calcCtrl.downPayment = 0;
					}
				} else if ($calcCtrl.vin !== '' && $calcCtrl.isApplyForFinancingMode) {
					$calcCtrl.downPaymentCpt++;
					let halfMaxFinanceAdjustment = Math.round(($calcCtrl.getMaxFinanceAdjustment() / 2) / 50) * 50;
					if ($calcCtrl.downPaymentCpt === 1 && $calcCtrl.downPayment === halfMaxFinanceAdjustment) {
						$calcCtrl.downPayment = 0;
					}
				}
			});

			// register ourselves to the payment update listener
			if ($calcCtrl.selfHook) {
				$calcCtrl.selfHook($calcCtrl);
			}

            // expose ourself to window.fca namespace (for debug purpose mostly)
			window.fca = window.fca || {};
			window.fca.fcaCalculator = $calcCtrl;
		};

		$calcCtrl.getAriaLabelPaintChipSelected = () => {
			return `${$translate.instant('paint-chips.ariaLabelSelected')}`;
		};

		$calcCtrl.focusTrap = (e) => {
			if (e.key === 'Tab' || e.code === 'Tab') {
				if ( e.shiftKey ) {
					if (document.activeElement === $calcCtrl.firstFocusableElement) {
						$calcCtrl.lastFocusableElement.focus();
						e.preventDefault();
					}
				} else {
					if (document.activeElement === $calcCtrl.lastFocusableElement) {
						$calcCtrl.firstFocusableElement.focus();
						e.preventDefault();
					}
				}
			} else if(e.key === 'Escape' || e.code === 'Escape') {
				$calcCtrl.closeOverlay();
			} else {
				return;
			}
		};

		$calcCtrl.setFocusTrap = () => {
			$calcCtrl.modalDialog[0].removeEventListener('keydown', $calcCtrl.focusTrap);

			$calcCtrl.focusableElements = $calcCtrl.modalDialog.find('a[href]:not([disabled]), a[tabindex]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
			$calcCtrl.firstFocusableElement = $calcCtrl.focusableElements[0];
			$calcCtrl.lastFocusableElement = $calcCtrl.focusableElements[$calcCtrl.focusableElements.length - 1];

			$calcCtrl.firstFocusableElement.focus();

			$calcCtrl.modalDialog[0].addEventListener('keydown', $calcCtrl.focusTrap);
		};

		/**
		 * Safe scope apply method
		 */
		$calcCtrl.scopeApply = () => {
			$timeout(() => {
				$scope.$apply();
			});
		};

		/**
		 * This is triggered by the event 'jelly:updated-jelly'
		 */
		$calcCtrl.onJellyUpdated = (event, data) => {
			$calcCtrl.jellyUrl = data.jellyUrl;
		};

		/**
		 * Payment type event.
		 * - when the director dropdown is selected
		 * - when the payment summary dropdown is selected
		 * - when the user clicks on the tabs (cash/lease/finance)
		 * - when the user toggles the best rate
		 */
		$calcCtrl.onNavigationPaymentTypeChanged = (event, data) => {
			$calcCtrl.changeActiveTab(data.type);
			$calcCtrl.getElectricDiscounts();
		};

		/**
		 * This is triggered by the event 'calculator:update-calculator-parameters'
		 */
		$calcCtrl.onUpdateCalculatorParameters = (event, data) => {
			$calcCtrl.calcUpdatedValues();
		};

		$calcCtrl.onRefreshFocusTrap = (event) => {
			$calcCtrl.setFocusTrap();
		};

		/**
		 * This is triggered by the event 'calculator:recalculate'
		 */
		$calcCtrl.onCalculatorRecalculate = (event, data) => {
			$calcCtrl.calcUpdatedValues();
		};

		/**
		 * This is triggered by the event 'calculator:set-aff-programs'
		 *
		 * Only used in the context of the apply for financing context.
		 *
		 */
		$calcCtrl.onCalculatorApplyForFinancingPrograms = (event, data) => {
			$calcCtrl.setModelYearProgramsLegacy($calcCtrl.provinceCode);

			$calcCtrl.basePrice = Number(data.pricing.base.msrp);
			$calcCtrl.acTax = Number(data.pricing.acTax.msrp || 0);
			$calcCtrl.freight = Number(data.pricing.freight.msrp || 0);
			$calcCtrl.greenLevy = Number(data.pricing.greenLevy.msrp || 0);
			$calcCtrl.luxuryTax = Number(data.pricing.monroney.luxuryTax || 0);
			$calcCtrl.options = Number(data.pricing.options.msrp || 0);

			$calcCtrl.patchLeaseTerms(data.pricing); // patch the lease terms
			$calcCtrl.pricing = data.pricing;
			$calcCtrl.updateCalculatorParameters(true);
			$calcCtrl.assignPricingData(); // This is due to terms not being initialized before the first program selection
		};

		/**
		 * This is triggered by the event 'calculator:set-programs'
		 *
		 * This handler is triggered when an option selection is confirmed (through the alert window dialog)
		 *
		 * It is also triggered when the trim group is selected in the apply for financing component.
		 *
		 */
		$calcCtrl.onCalculatorSetPrograms = (event, data) => {
			if ($calcCtrl.isApplyForFinancingMode) {
				$calcCtrl.setModelYearProgramsLegacy($calcCtrl.provinceCode);
			} else {
				$calcCtrl.setModelYearPrograms();
			}
		};

		/**
		 * This is meant to fix incentive names, in the case we have localized strings in the incentives.
		 */
		$calcCtrl.setIncentiveNames = (program) => {
			if (program
				&& program.incentives
				&& program.incentives.length > 0) {
				program.incentives.forEach(incentive => {
					if (!incentive.name
						&& incentive.desc[$calcCtrl.language]) {
						incentive.name = incentive.desc[$calcCtrl.language];
					}
				});
			}
		};

		$calcCtrl.getAffiliateLogoSource = () => {
			return $calcCtrl.affiliateLogoSource;
		};

		/**
		 * This is called whenever any binding value changes
		 * It is called BEFORE the onInit method (allowing us to capture the initial values).
		 */
		$calcCtrl.$onChanges = data => {
			//no-op
		};

		/**
		 * Called whenever the location changes
		 * Both geolocator.status.component and geolocator.component emit the same location change, this means we can (and will) receive it twice.
		 */
		$calcCtrl.onLocationChange = (event, data) => {
			if (data
				&& data.length > 0
				&& data[0].province
				&& data[0].province !== $calcCtrl.provinceCode) {
				$calcCtrl.provinceCode = data[0].province;  // if we really get a new province, update it here
				$calcCtrl.provinceChanged();
			}
		};

		$calcCtrl.setSalesTax = () => {
			let promise = calculatorService.getSalesTax($calcCtrl.modelYearId);
			promise.then(value => {
				$calcCtrl.salesTaxList = value.data;

				$calcCtrl.currentSalesTax();
				$calcCtrl.updateCalculatorParameters(true);
				$calcCtrl.calculatePayments();

			}, value => {
				console.error('Sales Tax api return 404, return value:', value);
				$calcCtrl.salesTaxList = [];
				$calcCtrl.currentSalesTax();
				$calcCtrl.updateCalculatorParameters(true);
				$calcCtrl.calculatePayments();
			});
		};

		$calcCtrl.getMaxFinanceAdjustment = () => {
			let result = $calcCtrl.basePrice
				+ $calcCtrl.freight
				+ $calcCtrl.acTax
				+ $calcCtrl.greenLevy
				+ $calcCtrl.options
				+ $calcCtrl.dealerDelta
				- $calcCtrl.discountBeforeTaxTotal
				- $calcCtrl.discountAfterTaxTotal;
			// allow up to twice that amount
			return result * 2;
		};

		$calcCtrl.provinceChanged = () => {
			configService.setProvinceCode($calcCtrl.provinceCode); // not sure we should be updating the config service's province code
			$calcCtrl.currentSalesTax(); // update the sales taxes

			// trigger re-selecting the proper program before getting the discounts
			$calcCtrl.assignPricingData();
			$calcCtrl.calcUpdatedValues();
			$calcCtrl.getElectricDiscounts();

			// re-calculate payments
			if ($calcCtrl.isApplyForFinancingMode) {
				$calcCtrl.setModelYearProgramsLegacy($calcCtrl.provinceCode);
			} else {
				$calcCtrl.calculatePayments();
			}
		};

		$calcCtrl.currentSalesTax = () => {
			let matchTax = $calcCtrl.salesTaxList.filter(province => province.provinceCode.toLowerCase() === $calcCtrl.provinceCode.toLowerCase());
			if (matchTax.length > 0) {
				$calcCtrl.salesTax = matchTax[0];
			}
		};

		$calcCtrl.setElectricDiscounts = (getDiscounts = true) => {
			let promise = calculatorService.getElectricDiscounts();
			promise.then(value => {
				$calcCtrl.electricDiscountsList = value.data;
				if(getDiscounts) {
					$calcCtrl.getElectricDiscounts();
				}

			}, value => {
				console.error('Electric discounts api returned 404, return value:', value);
				$calcCtrl.electricDiscountsList = [];
			});
		};

		$calcCtrl.getElectricDiscounts = (isProvincial = true) => {
			$calcCtrl.updateCalculatorParameters(false);

			let provinceCode = isProvincial ? $calcCtrl.provinceCode : "fed";
			let discount = $calcCtrl.electricDiscountsList.filter(discount => discount.provinceCode.toLowerCase() === provinceCode.toLowerCase());

			if (discount.length > 0) {
				let discountOffer = discount[0].discountOfferList.filter(discountOffer => discountOffer.type.toLowerCase() === $calcCtrl.activeTab.toLowerCase());

				if (discountOffer.length > 0) {
					let discountAmounts = discountOffer[0].discountAmountList ? discountOffer[0].discountAmountList : [];

					if (isProvincial) {
						$calcCtrl.currentElectricProvincialAmounts = discountAmounts.length > 0 ? discountAmounts : [];
					} else {
						$calcCtrl.currentElectricFederalAmounts = discountAmounts.length > 0 ? discountAmounts : [];
					}
					$calcCtrl.getElectricPercentageDiscount(isProvincial);
				} else $calcCtrl.clearElectricAmountsList(isProvincial);
			} else $calcCtrl.clearElectricAmountsList(isProvincial);
		};

		$calcCtrl.getElectricPercentageDiscount = (isProvincial = true) => {
			let term = 0;

			//if lease or finance term not initialized
			$calcCtrl.updateCalculatorParameters(false);


			if($calcCtrl.activeTab == 'finance') {
				term = $calcCtrl.financeInterestRate[$calcCtrl.financeTerm].key;
			} else if($calcCtrl.activeTab == 'lease') {
				$calcCtrl.calcUpdatedValues();
				$calcCtrl.leaseTerm = getCurrentLeaseTermFromSlider(false);
				term = $calcCtrl.leaseInterestRate[$calcCtrl.leaseTerm].key;
			}

			if(isProvincial) {
				$calcCtrl.currentElectricProvincialPercentage = $calcCtrl.setElectricPercentageDiscount(term, isProvincial);
				$calcCtrl.calculateElectricDiscounts();
			} else {
				$calcCtrl.currentElectricFederalPercentage = $calcCtrl.setElectricPercentageDiscount(term, isProvincial);
			}
		};

		$calcCtrl.setElectricPercentageDiscount = (term, isProvincial = true) => {
			let percentageResult = $calcCtrl.defaultElectricPercentage;
			let amounts = isProvincial ? $calcCtrl.currentElectricProvincialAmounts : $calcCtrl.currentElectricFederalAmounts;
			amounts = amounts.sort((a,b)=> (a.minTerm > b.minTerm) ? 1 : -1);

			/* In case we have maxAmount = [{minTerm : 30}, {maxTerm : ""}, {percentage = 100}]
			 * and term = 31+
			 */
			if (amounts.length > 0) {
				let maxAmount = amounts[amounts.length - 1];
				let maxAmountMaxTerm = maxAmount.maxTerm != undefined && maxAmount.maxTerm > 0
					? amounts[amounts.length - 1].maxTerm
					: amounts[amounts.length - 1].minTerm;

				if (term >= maxAmountMaxTerm) {
					percentageResult = amounts[amounts.length - 1].percentage;
				} else {
					amounts.forEach((amount) => {
						let minTerm = amount.minTerm || 0;
						let maxTerm = amount.maxTerm || 0;
						let percentage = amount.percentage;

						if (minTerm > 0 && maxTerm > 0) {
							if (term >= minTerm && term <= maxTerm) {
								percentageResult = percentage;
							}
						} else if ( minTerm > 0 && term >= minTerm) {
							percentageResult = percentage;
						} else if ( maxTerm > 0 && term <= maxTerm) {
							percentageResult = percentage;
						} else if ( maxTerm > 0 && term >= maxTerm) {
							percentageResult = percentage;
						}
					});
				}
			}
			return percentageResult;
		};

		$calcCtrl.calculateElectricDiscounts = () => {
			let incentives = [];

			if ($calcCtrl.activeTab == 'cash') {
				incentives = $calcCtrl.cashIncentives;
			} else if ($calcCtrl.activeTab == 'finance') {
				incentives = $calcCtrl.financeIncentives;
			} else if ($calcCtrl.activeTab == 'lease') {
				incentives = $calcCtrl.leaseIncentives;
			}

			if (incentives) {
				incentives.forEach((incentive) => {
					if (incentive.governmentRebate == true) {
						if (incentive.provinceCode.toLowerCase() == $calcCtrl.provinceCode.toLowerCase()) {
							incentive.cash = incentive.initialCash * ($calcCtrl.currentElectricProvincialPercentage / 100);
						} else {
							$calcCtrl.getElectricDiscounts(false);
							incentive.cash = incentive.initialCash * ($calcCtrl.currentElectricFederalPercentage/ 100);
						}
					}
				});
			}
			$calcCtrl.updateCalculatorParameters(false);
			$calcCtrl.calculatePayments();
		};

		$calcCtrl.clearElectricAmountsList = (isProvincial = true) => {
			if (isProvincial) {
				$calcCtrl.currentElectricProvincialAmounts = [];
				$calcCtrl.currentElectricProvincialPercentage = $calcCtrl.defaultElectricPercentage;
			} else {
				$calcCtrl.currentElectricFederalAmounts = [];
				$calcCtrl.currentElectricFederalPercentage = $calcCtrl.defaultElectricPercentage;
			}
		};


		/**
		 * This is used when in build and price mode
		 */
		$calcCtrl.setModelYearPrograms = () => {
			if (configService.pricing) {
				// no need to to anything with it if the object is the same
				if (configService.pricing !== $calcCtrl.pricing) {
					let pricing = configService.pricing;

					$calcCtrl.basePrice = Number(pricing.base.msrp);
					$calcCtrl.acTax = Number(pricing.acTax.msrp || 0);
					$calcCtrl.freight = Number(pricing.freight.msrp || 0);
					$calcCtrl.greenLevy = Number(pricing.greenLevy.msrp || 0);
					$calcCtrl.luxuryTax = Number(pricing.monroney.luxuryTax || 0);
					$calcCtrl.options = Number(pricing.options.msrp || 0);

					$calcCtrl.patchLeaseTerms(pricing);
					$calcCtrl.pricing = pricing; // replace the calculator's pricing object with the new one
					$calcCtrl.assignPricingData(); // assign pricing data (select the program, etc)
					$calcCtrl.updateCalculatorParameters(false); // we don't need to reinitialize the terms
					$calcCtrl.currentModelYearId = $calcCtrl.modelYearId;
					$calcCtrl.calculatePayments();
				}
			}
		};

		/**
		 * This patch creates fake terms in the programs, so that we can have all durations
		 * available.
		 * This is required for the case where the alternative lease has more durations than the standard lease
		 * (or vice versa)
		 */
		$calcCtrl.patchLeaseTerms = (pricing) => {
			let leaseDurations = [];
			let leaseProgramTermLists = [];

			// add all term lists from lease
			if (pricing.lease
				&& pricing.lease.terms) {
				pricing.lease.terms.forEach(termList => {
					leaseProgramTermLists.push(termList);
				});

			}

			// add all terms lists from alternative lease
			if (pricing.altLease
				&& pricing.altLease.terms) {
				pricing.altLease.terms.forEach(termList => {
					leaseProgramTermLists.push(termList);
				});

			}

			// build the list of all possible durations across all term lists
			leaseProgramTermLists.forEach(termList => {
				termList.terms.forEach( term => {
						if (!leaseDurations.find(element => Number(element) === Number(term.duration))) {
							leaseDurations.push(term.duration);
						}
					}
				);
			});

			// make sure we have all term durations in all term lists
			leaseProgramTermLists.forEach(termList => {
				leaseDurations.forEach(leaseDuration => {
					// that lease term list doesn't contain any entry for that duration, push a fake one
					if (!termList.terms.find(leaseTerm => Number(leaseTerm.duration) === Number(leaseDuration))) {
						termList.terms.push({duration: leaseDuration, rate: null, residual: "0"});
					}
				});
			});

			// now, make sure the terms are sorted
			leaseProgramTermLists.forEach(termList => {
				termList.terms.sort((terma, termb) => {
					return Number(terma.duration) - Number(termb.duration);
				});
			});
		};

		/**
		 * assign local variable and program from $calcCtrl.pricing data previously assigned
		 */
		$calcCtrl.assignPricingData = () => {
			if ($calcCtrl.pricing) {
				// cash is the net amount, and the incentive amount showed in th cash tab
				const cashPrograms = $calcCtrl.pricing.cash;

				// define if it has hybrid incentives on finance
				$calcCtrl.hasHybridIncentives = false;
				$calcCtrl.disclaimer = {};

				if (cashPrograms) {
					$calcCtrl.cashIncentives = cashPrograms.incentives;
					$calcCtrl.hasHybridIncentives = cashPrograms.hybridIncentivized;
					$calcCtrl.cashDealerDelta = cashPrograms.dealerDelta; // set the dealer delta (if any)
					if (cashPrograms.disclaimer) {
						$calcCtrl.disclaimer["cash"] = cashPrograms.disclaimer;
					}
				}

				// before we do anything, sanitize finance programs
				if ($calcCtrl.pricing.finance) {
					financeProgramService.sanitizeFinanceProgramTerms($calcCtrl.pricing.finance.terms, $calcCtrl.calculatorDefaultFinanceRate);

				}
				if ($calcCtrl.pricing.altFinance) {
					financeProgramService.sanitizeFinanceProgramTerms($calcCtrl.pricing.altFinance.terms, $calcCtrl.calculatorDefaultFinanceRate);
				}

				const financeProgram = $calcCtrl.selectFinanceProgram();
				if (financeProgram) {
					// this is called programs but they really are terms
					$calcCtrl.financePrograms = financeProgram.terms;
					$calcCtrl.financeIncentives = financeProgram.incentives;
					$calcCtrl.financeDealerDelta = financeProgram.dealerDelta;
					$calcCtrl.programs["finance"] = financeProgram;

					if (financeProgram.disclaimer) {
						$calcCtrl.disclaimer["finance"] = financeProgram.disclaimer;
					}
				}

				// the finance program has been changed
				if (financeProgram !== $calcCtrl.financeProgram) {
					$calcCtrl.financeProgram = financeProgram;

					$calcCtrl.financeDiscountTotalBeforeTax = $calcCtrl.sumCashIncentives($calcCtrl.financeIncentives, false);
					$calcCtrl.financeDiscountTotalAfterTax = $calcCtrl.sumCashIncentives($calcCtrl.financeIncentives, true);
				}

				$calcCtrl.hasLease = !!$calcCtrl.pricing.lease;
				$calcCtrl.hasAltLease = !!$calcCtrl.pricing.altLease;

				const leaseProgram = $calcCtrl.selectLeaseProgram();
				if (leaseProgram) {
					// sort lease term
					leaseProgram.terms.sort((a, b) => {
						return a.km - b.km;
					});

					// this is called programs but they really are terms
					$calcCtrl.leasePrograms = leaseProgram.terms;
					$calcCtrl.leaseIncentives = leaseProgram.incentives;
					$calcCtrl.leaseDealerDelta = leaseProgram.dealerDelta;
					$calcCtrl.programs["lease"] = leaseProgram;

					if (leaseProgram.disclaimer) {
						$calcCtrl.disclaimer["lease"] = leaseProgram.disclaimer;
					}
				}

				// the lease program has been changed
				if (leaseProgram !== $calcCtrl.leaseProgram) {
					$calcCtrl.leaseProgram = leaseProgram;

					$calcCtrl.leaseDiscountTotalBeforeTax = $calcCtrl.sumCashIncentives($calcCtrl.leaseIncentives, false);
					$calcCtrl.leaseDiscountTotalAfterTax = $calcCtrl.sumCashIncentives($calcCtrl.leaseIncentives, true);
					// Update electric discounts
					$calcCtrl.getElectricDiscounts();
				}

				$calcCtrl.formattedPricingData = $calcCtrl.formatPricingDataForCalculator();

				// if we don't have lease, fall-back to cash
				if (!leaseProgram
					&& $calcCtrl.activeTab === 'lease') {
					$calcCtrl.broadCastPaymentChange('cash');
				}

				// assign total discount
				if ($calcCtrl.activeTab == 'cash') {
					$calcCtrl.discountBeforeTaxTotal = $calcCtrl.cashDiscountTotalBeforeTaxes ? $calcCtrl.cashDiscountTotalBeforeTaxes : 0;
					$calcCtrl.discountAfterTaxTotal = $calcCtrl.cashDiscountTotalAfterTaxes ? $calcCtrl.cashDiscountTotalAfterTaxes : 0;
					$calcCtrl.dealerDelta = ($calcCtrl.cashDealerDelta || 0);
				} else if ($calcCtrl.activeTab == 'finance') {
					$calcCtrl.discountBeforeTaxTotal = $calcCtrl.financeDiscountTotalBeforeTax ? $calcCtrl.financeDiscountTotalBeforeTax : 0;
					$calcCtrl.discountAfterTaxTotal = $calcCtrl.financeDiscountTotalAfterTax ? $calcCtrl.financeDiscountTotalAfterTax : 0;
					$calcCtrl.dealerDelta = ($calcCtrl.financeDealerDelta || 0);
				} else if ($calcCtrl.activeTab == 'lease') {
					$calcCtrl.discountBeforeTaxTotal = $calcCtrl.leaseDiscountTotalBeforeTax ? $calcCtrl.leaseDiscountTotalBeforeTax : 0;
					$calcCtrl.discountAfterTaxTotal = $calcCtrl.leaseDiscountTotalAfterTax ? $calcCtrl.leaseDiscountTotalAfterTax : 0;
					$calcCtrl.dealerDelta = ($calcCtrl.leaseDealerDelta || 0);
				}
				$calcCtrl.maxFinanceAdjustment = $calcCtrl.getMaxFinanceAdjustment();
			}
		};

		$calcCtrl.validateFinanceProgramValues = () => {
			let financeKeys = [36, 48, 60, 72, 84, 96];
			financeKeys.forEach(financeKey => {
				if (!$calcCtrl.financePrograms.find(element => Number(element.duration) === financeKey)) {
					$calcCtrl.financePrograms.push({
						duration: financeKey,
						rate: $calcCtrl.calculatorDefaultFinanceRate
					});
				}
			});

			$calcCtrl.financePrograms.sort((a, b) => {
				return a.duration - b.duration;
			});
		};

		$calcCtrl.formatPricingDataForCalculator = () => {
			let formattedPricingData = {};
			if ($calcCtrl.financePrograms) {
				// initialized property
				formattedPricingData["finance"] = [];
				let financeObject = {};

				// assign finance rates to finance object
				financeObject['term'] = $calcCtrl.formatTermAsMap($calcCtrl.financePrograms, 'duration', 'rate');
				formattedPricingData["finance"].push(financeObject);
			}

			// those are not programs, they are the lease terms
			if ($calcCtrl.leasePrograms) {
				// initialized property
				formattedPricingData["lease"] = [];
				formattedPricingData["residual"] = [];
				let leaseObject = {};

				// recover the low mileage entry to store rate for calculator
				let lowestKmLimit = Math.min.apply(null, $calcCtrl.leasePrograms.map(
					function(term) {
						return term.km;
					})
				);

				let LowestKmTerm = $calcCtrl.leasePrograms.filter(term => Number(term.km) == Number(lowestKmLimit));

				if(LowestKmTerm.length > 0) {
					// assign low rates to lease object
					leaseObject['term'] = $calcCtrl.formatTermAsMap(LowestKmTerm[0].terms, 'duration', 'rate');
					formattedPricingData["lease"].push(leaseObject);
				}

				// format residual list
				$calcCtrl.leasePrograms.forEach((mileageRates) => {
					let residualObject = {};
					residualObject['mileage'] = mileageRates.km;
					residualObject['term'] = $calcCtrl.formatTermAsMap(mileageRates.terms, 'duration', 'residual');
					formattedPricingData["residual"].push(residualObject);
				});
			}
			return formattedPricingData;
		};

		$calcCtrl.formatTermAsMap = (terms, key, value) => {
			let termMap = {};
			terms.forEach((term, index) => termMap[term[key]] = Number(term[value]));

			return termMap;
		};

		$calcCtrl.updateCalculatorParameters = (needTermInit = true) => {
			$calcCtrl.cashDiscountTotalBeforeTaxes = $calcCtrl.sumCashIncentives($calcCtrl.cashIncentives, false);
			$calcCtrl.cashDiscountTotalAfterTaxes = $calcCtrl.sumCashIncentives($calcCtrl.cashIncentives, true);

			$calcCtrl.financeDiscountTotalBeforeTax = $calcCtrl.sumCashIncentives($calcCtrl.financeIncentives, false);
			$calcCtrl.financeDiscountTotalAfterTax = $calcCtrl.sumCashIncentives($calcCtrl.financeIncentives, true);

			$calcCtrl.leaseDiscountTotalBeforeTax = $calcCtrl.sumCashIncentives($calcCtrl.leaseIncentives, false);
			$calcCtrl.leaseDiscountTotalAfterTax = $calcCtrl.sumCashIncentives($calcCtrl.leaseIncentives, true);

			// assign total discount
			if($calcCtrl.activeTab == 'cash') {
				$calcCtrl.discountBeforeTaxTotal = $calcCtrl.cashDiscountTotalBeforeTaxes ? $calcCtrl.cashDiscountTotalBeforeTaxes : 0;
				$calcCtrl.discountAfterTaxTotal = $calcCtrl.cashDiscountTotalAfterTaxes ? $calcCtrl.cashDiscountTotalAfterTaxes : 0;
				$calcCtrl.dealerDelta = ($calcCtrl.cashDealerDelta || 0);
			} else if ($calcCtrl.activeTab == 'finance') {
				$calcCtrl.discountBeforeTaxTotal = $calcCtrl.financeDiscountTotalBeforeTax ? $calcCtrl.financeDiscountTotalBeforeTax : 0;
				$calcCtrl.discountAfterTaxTotal = $calcCtrl.financeDiscountTotalAfterTax ? $calcCtrl.financeDiscountTotalAfterTax : 0;
				$calcCtrl.dealerDelta = ($calcCtrl.financeDealerDelta || 0);
			} else if ($calcCtrl.activeTab == 'lease') {
				$calcCtrl.discountBeforeTaxTotal = $calcCtrl.leaseDiscountTotalBeforeTax ? $calcCtrl.leaseDiscountTotalBeforeTax : 0;
				$calcCtrl.discountAfterTaxTotal = $calcCtrl.leaseDiscountTotalAfterTax ? $calcCtrl.leaseDiscountTotalAfterTax : 0;
				$calcCtrl.dealerDelta = ($calcCtrl.leaseDealerDelta || 0);
			}

			// applyForFinancingIncentivesTotal is overriding discount values
			if ($calcCtrl.applyForFinancingIncentivesTotal > 0) {
				$calcCtrl.discountAfterTaxTotal = 0;
				$calcCtrl.discountBeforeTaxTotal = $calcCtrl.applyForFinancingIncentivesTotal;
			}

			let maxFinanceAdjustment =
				$calcCtrl.basePrice
				+ $calcCtrl.freight
				+ $calcCtrl.acTax
				+ $calcCtrl.greenLevy
				+ ($calcCtrl.luxuryTax || 0)
				+ ($calcCtrl.dealerDelta || 0)
				+ $calcCtrl.options
				- $calcCtrl.discountBeforeTaxTotal
				- $calcCtrl.discountAfterTaxTotal;

			if ($calcCtrl.dealerPrice > 0) {
				maxFinanceAdjustment = $calcCtrl.dealerPrice;
			}

			// admin fees are already calculated in sni mode
			if (!$calcCtrl.vin && $calcCtrl.adminFee > 0) {
				maxFinanceAdjustment += $calcCtrl.adminFee;
			}

			$calcCtrl.maxFinanceAdjustment = maxFinanceAdjustment;

			$calcCtrl.calculatorData = calculatorService.buildCalculatorData($calcCtrl.formattedPricingData, $calcCtrl.packageAndOptionsCode, $calcCtrl.calculatorDefaultFinanceRate);

			if ($calcCtrl.calculatorData.financeInterestRate) {
				$calcCtrl.financeInterestRate = $calcCtrl.convertObjectToArray($calcCtrl.calculatorData.financeInterestRate);
			} else {
				$calcCtrl.financeInterestRate = [];
			}

			if ($calcCtrl.calculatorData.leaseInterestRate) {
				$calcCtrl.leaseInterestRate = $calcCtrl.convertObjectToArray($calcCtrl.calculatorData.leaseInterestRate);
			} else {
				$calcCtrl.leaseInterestRate = [];
			}

			if ($calcCtrl.calculatorData.residualList) {
				$calcCtrl.residualList = $calcCtrl.convertObjectToArray($calcCtrl.calculatorData.residualList);
			} else {
				$calcCtrl.residualList = [];
			}

			if(needTermInit) {
				// initialize the finance term
				let financeTermInitialized = false;

				// if there's a default finance term, try to accommodate it
				if($calcCtrl.defaultFinanceTerm) {
					let financeTerm = null;

					// determine the index that corresponds
					for(var i = 0; i < $calcCtrl.financeInterestRate.length; i += 1) {
						if($calcCtrl.financeInterestRate[i]["key"] === $calcCtrl.defaultFinanceTerm) {
							financeTerm = i;
							break;
						}
					}

					if(financeTerm) {
						$calcCtrl.financeTerm = i;
						financeTermInitialized = true;
					}
				}

				// initialize the finance term if it hasn't been initialized yet
				if (!financeTermInitialized) {
					$calcCtrl.financeTerm = getCurrentFinanceTermFromSlider(needTermInit);
				}

				// initialize the lease term
				let leaseTermInitialized = false;

				// if there's a default lease term, try to accommodate it
				if ($calcCtrl.defaultLeaseTerm) {
					let leaseTerm = null;
					for (var i = 0; i < $calcCtrl.leaseInterestRate.length; i += 1) {
						if ($calcCtrl.leaseInterestRate[i]["key"] === $calcCtrl.defaultLeaseTerm) {
							leaseTerm = i;
							break;
						}
					}
					if (leaseTerm) {
						$calcCtrl.leaseTerm = i;
						leaseTermInitialized = true;
					}
				}
				if (!leaseTermInitialized) {
					$calcCtrl.leaseTerm = getCurrentLeaseTermFromSlider(needTermInit);
				}

				// We take the maximum value in the array block
				$calcCtrl.annualMileageChoice = $calcCtrl.setDefaultMileage();
			}
		};

		$calcCtrl.selectProgram = (programs, acode) => {
			let filteredProgram = [];
			let program = {};
			if (programs && programs.length > 0) {
				filteredProgram = programs.filter(program => program.acode === acode);
				if (filteredProgram.length > 0) {
					program = filteredProgram[0];
				}
			}
			return program;
		};

		/**
		 * This is called whenever the user clicks on the toggle in the calculator summary.
		 * The boolean value of $calcCtrl.useBestRate also changes before this is called
		 */
		$calcCtrl.toggleBestRate = () => {
			$calcCtrl.changePaymentType();
		};

		/**
		 * Triggered by the using clicking on the sales tax input box
		 */
		$calcCtrl.toggleSalesTax = () => {
			$calcCtrl.includeSalesTax = !$calcCtrl.includeSalesTax;

			// if the sales tax is toggled, the payments need to be re-calculated
			$calcCtrl.calculatePayments();
		};

		/**
		 * - Broadcasts the new payment type
		 * - Triggers re-calculation
		 * - Resets the term
		 *
		 *  TODO: altFinance is not the same as lease best rate, we should be broadcasting different data
		 *  we should be broadcasting: finance-best-rate, finance-best-payment, lease-best-rate, lease-best-payment
		 */
		$calcCtrl.changePaymentType = () => {
			let paymentType = null;

			if ($calcCtrl.activeTab === 'finance' || $calcCtrl.activeTab === 'altFinance') {
				paymentType = $calcCtrl.useBestRate ? 'altFinance' : 'finance';
			} else if ($calcCtrl.activeTab === 'lease') {
				paymentType = 'lease';
			} else {
				paymentType = 'finance'; // fall back on finance
				console.error("could not determine payment type for type " + $calcCtrl.activeTab + " and best rate " + $calcCtrl.useBestRate);
			}
			// trigger re-selecting the proper program
			$calcCtrl.assignPricingData();
			$calcCtrl.calcUpdatedValues();
			// broadcast the payment change (so that the drop-downs can update the payment type)
			$calcCtrl.broadCastPaymentChange(paymentType);
		};

		/**
		 * This sets the term according to the best rate or best-payment.
		 *
		 * Best rate:
		 *  - Set the term to the lowest interest rate with the longest term possible, ie:
		 *    24 0%
		 *    36 0%
		 *    48 0% <--
		 *    60 1%
		 *    96 2%
		 * Best payment:
		 *  - Set the term to the longest term (the actual payment doesn't matter)
		 */
		$calcCtrl.resetTerm = () => {
			// only attempt to reset the terms if we have pricing information
			if ($calcCtrl.pricing) {
				let pricing = $calcCtrl.pricing;
				if ($calcCtrl.useBestRate) {
					// when using best rate, we only care about alternative programs
					if (pricing.altFinance
						&& pricing.altFinance.terms
						&& pricing.altFinance.terms.length > 0) {
						let altFinanceTerms = pricing.altFinance.terms;
						let term = $calcCtrl.getLowestRateWithLongestTerm(altFinanceTerms);
						// set the slider according to the term (the index must correspond to the index in the terms array)
						if (term) {
							$calcCtrl.financeTerm = altFinanceTerms.indexOf(term);
						}
					}
					if (pricing.altLease
						&& pricing.altLease.terms
						&& pricing.altLease.terms.length > 0) {
						// for selection purpose, we don't care if it's low/high mileage, use the first one
						let altLeaseTerms = pricing.altLease.terms[0].terms;
						let term = $calcCtrl.getLowestRateWithLongestTerm(altLeaseTerms);
						// set the slider according to the term (the index must correspond to the index in the terms array)
						if (term) {
							$calcCtrl.leaseTerm = altLeaseTerms.indexOf(term);
						}
					}
				} else {
					// only look in finance terms (no need to look in alternative finance)
					if (pricing.finance
						&& pricing.finance.terms) {
						let term = $calcCtrl.getLongestTerm(pricing.finance.terms);
						// set the slider according to the term (the index must correspond to the index in the terms array)
						if (term) {
							$calcCtrl.financeTerm = pricing.finance.terms.indexOf(term);
						}
					}
					// it's possible not to have a lease and only have an alternative lease
					let leaseTerms = null;
					if (pricing.lease
						&& pricing.lease.terms
						&& pricing.lease.terms.length > 0) {
						leaseTerms = pricing.lease.terms[0].terms;
					}
					// there's no lease, there may be an alternative lease
					if (!leaseTerms
						&& pricing.altLease
						&& pricing.altLease.terms
						&& pricing.altLease.terms.length > 0) {
						leaseTerms = pricing.altLease.terms[0].terms;
					}
					// if we have some lease terms, set the term to the longest possible
					if (leaseTerms) {
						let term = $calcCtrl.getLongestTerm(leaseTerms);
						// set the slider according to the term (the index must correspond to the index in the terms array)
						if (term) {
							$calcCtrl.leaseTerm = leaseTerms.indexOf(term);
						}
					}
				}
			}
		};


		/**
		 * Determine the term that has the lowest rate with the longest term possible
		 * For example:
		 *    24 0%
		 *    36 0%
		 *    48 0% <--
		 *    60 1%
		 *    96 2%
		 */
		$calcCtrl.getLowestRateWithLongestTerm = (terms) => {
			let result = null;
			if (terms) {
				result = terms.reduce(function(term1, term2) {
					let reduced = null;
					if (parseFloat(term1.rate) < parseFloat(term2.rate)) {
						reduced = term1;
					} else if (parseFloat(term1.rate) > parseFloat(term2.rate)) {
						reduced = term2;
					} else { // both terms have equal rate, use the longest term
						if (parseInt(term1.duration) > parseInt(term2.duration)) {
							reduced = term1;
						} else {
							reduced = term2;
						}
					}
					return reduced;
				});
			}
			return result;
		};

		/**
		 * Determine the term that has the longest duration
		 */
		$calcCtrl.getLongestTerm = (terms) => {
			let result = null;
			if (terms) {
				result = terms.reduce(function(term1, term2) {
					let reduced = term1;
					if (parseInt(term1.duration) > parseInt(term2.duration)) {
						reduced = term1;
					} else { // both terms have equal rate, use the longest term
						reduced = term2;
					}
					return reduced;
				});
			}
			return result;
		};

		/**
		 * User modifies the down payment (through the slider or the input button).
		 *
		 * The $calcCtrl.downPayment has been modified
		 */
		$calcCtrl.downPaymentChanged = (data) => {
			if ($calcCtrl.downPayment !== $calcCtrl.previousDownPayment) {
				$calcCtrl.assignPricingData(); // make sure we switch program (if necessary)
				$calcCtrl.calcUpdatedValues();
			}
			$calcCtrl.previousDownPayment = $calcCtrl.downPayment; // keep the current value
			$calcCtrl.firstDownPaymentInput = false;// this is not the first input anymore
		};

		/**
		 * User modifies the trade-in value (through the slider or the input button).
		 *
		 * The $calcCtrl.tradeValue has been modified
		 */
		$calcCtrl.tradeInValueChanged = () => {
			if ($calcCtrl.tradeValue !== $calcCtrl.previousTradeInValue) {
				if ($calcCtrl.previousTradeInValue === 0) {
					$calcCtrl.firstTradeInValue = true;
				} else {
					$calcCtrl.firstTradeInValue = false;
				}
				$calcCtrl.previousTradeInValue = $calcCtrl.tradeValue;

				// re-calculate
				$calcCtrl.assignPricingData(); // make sure we switch program (if necessary)
				$calcCtrl.calcUpdatedValues();
			}
		};

		/**
		 * User modifies the owe on trade (through the slider or the input button).
		 *
		 * The $calcCtrl.tradeOwed has been modified
		 */
		$calcCtrl.oweOnTradeChanged = () => {
			if ($calcCtrl.tradeOwed !== $calcCtrl.previousTradeOwed) {
				// on safari, a change on the trade-in value triggers a jump to half owe-on trade
				// if you're on safari, you simply can't jump to half the trade-in-value
				let cancelChange = false;
				if (IS_SAFARI
					&& $calcCtrl.tradeValue
					&& Math.round($calcCtrl.tradeValue / 2) === $calcCtrl.tradeOwed) {
					$calcCtrl.tradeOwed = $calcCtrl.previousTradeOwed;
					cancelChange = true;
				}

				if (!cancelChange) {
					$calcCtrl.previousTradeOwed = $calcCtrl.tradeOwed;
					// re-calculate
					$calcCtrl.assignPricingData(); // make sure we switch program (if necessary)
					$calcCtrl.calcUpdatedValues();
				}
			}
		};

		$calcCtrl.paymentFrequencyChanged = () => {
			$calcCtrl.assignPricingData(); // make sure we switch program (if necessary)
			$calcCtrl.calcUpdatedValues();
		};

		$calcCtrl.financeTermChanged = () => {
			$calcCtrl.assignPricingData(); // make sure we switch program (if necessary)
			$calcCtrl.calcUpdatedValues();
			$calcCtrl.getElectricPercentageDiscount();

		};

		$calcCtrl.leaseTermChanged = () => {
			$calcCtrl.assignPricingData(); // make sure we switch program (if necessary)
			$calcCtrl.calcUpdatedValues();
			$calcCtrl.getElectricPercentageDiscount();
		};

		$calcCtrl.leaseMileageChanged = () => {
			$calcCtrl.assignPricingData(); // make sure we switch program (if necessary)
			$calcCtrl.calcUpdatedValues();
		};

		/**
		 * Updates the net amount
		 */
		$calcCtrl.updateNetAmount = () => {
			$calcCtrl.assignPricingData();
			$calcCtrl.updateCalculatorParameters(false);

			$calcCtrl.cashNetAmount = $calcCtrl.calculateCashNetAmount(!!$calcCtrl.includeSalesTax);
			$calcCtrl.financeNetAmount = $calcCtrl.calculateFinanceNetAmount(!!$calcCtrl.includeSalesTax);
			$calcCtrl.leaseNetAmount = $calcCtrl.calculateLeaseNetAmount(!!$calcCtrl.includeSalesTax);
		};

		/**
		 * re-calculate all payment
		 */
		$calcCtrl.calcUpdatedValues = () => {
			$calcCtrl.calculatePayments();
		};

		$calcCtrl.toggle = (key) => {
			if (typeof ($calcCtrl[key]) === typeof (true)) {
				$calcCtrl[key] = !$calcCtrl[key];
			} else {
				// does not specify if the variable exists
				// console.log('this variable not a boolean');
			}
		};

		/**
		* fire function on enter keypress (accessibility)
		*/
		$calcCtrl.checkForEnterKey = ($event, fn, fnParam) => {
			if($event.key === 'Enter') {
				fn(fnParam);
			}
		};

		$calcCtrl.calculateFinancePaymentsPerTerm = () => {
			let amount = $calcCtrl.calculateBaseAmount();

			// admin fees are already calculated in sni mode
			if (!$calcCtrl.vin && $calcCtrl.adminFee) {
				// admin fees are the dealer charge added when the build and price is embedded has a frame in a dealer site
				amount += $calcCtrl.adminFee;
			}

			$calcCtrl.financeDiscountTotalBeforeTax = $calcCtrl.sumCashIncentives($calcCtrl.financeIncentives, false);
			$calcCtrl.financeDiscountTotalAfterTax = $calcCtrl.sumCashIncentives($calcCtrl.financeIncentives, true);

			let discountBeforeTaxTotal = $calcCtrl.financeDiscountTotalBeforeTax;
			let discountAfterTaxTotal = $calcCtrl.financeDiscountTotalAfterTax;
			if ($calcCtrl.dealerPrice > 0) {
				//When we have a dealer price, it already include the discounts
				amount = $calcCtrl.dealerPrice;
				discountBeforeTaxTotal = 0;
				discountAfterTaxTotal = 0;
			}

			let financeInterestRate = null;
			let financeTerm = null;
			$calcCtrl.paymentFinanceWithTax = null;
			$calcCtrl.paymentFinance = null;
			if ($calcCtrl.financeInterestRate.length > 0) {
				financeInterestRate = $calcCtrl.financeInterestRate[$calcCtrl.financeTerm].value;
				financeTerm = $calcCtrl.financeInterestRate[$calcCtrl.financeTerm].key;
				let financeData = {
					'amount': amount,
					'interestRate': financeInterestRate,
					'term': financeTerm,
					'downpayment': $calcCtrl.downPayment,
					'tradeInValue': $calcCtrl.tradeValue,
					'oweOnTrade': $calcCtrl.tradeOwed,
					'nbOfPaymentPerYear': $calcCtrl.paymentFrequencies[$calcCtrl.paymentFrequency].payments,
					'discountBeforeTax': discountBeforeTaxTotal,
					'discountAfterTax': discountAfterTaxTotal
				};

				$calcCtrl.paymentFinance = calculatorService.calculateFinancePayment(financeData);
				$calcCtrl.paymentFinanceWithTax = calculatorService.calculateFinancePaymentWithTax(financeData, $calcCtrl.salesTax, $calcCtrl.provinceCode, $calcCtrl.language);

				$calcCtrl.financeNetAmount = $calcCtrl.calculateFinanceNetAmount(!!$calcCtrl.includeSalesTax);
			}

			return $calcCtrl.paymentFinance.payment;
		};

		/**
		 * This is only used in the context of the best lease program selection.
		 * @param lease Lease program
		 * @returns lease payment object
		 */
		$calcCtrl.calculateLeasePaymentPerTerm = (lease) => {
			// Calculate lease
			let leaseAmount = $calcCtrl.calculateBaseAmount();

			let discountBeforeTaxTotal = $calcCtrl.sumCashIncentives(lease.incentives, false);
			let discountAfterTaxTotal = $calcCtrl.sumCashIncentives(lease.incentives, true);

			if ($calcCtrl.dealerPrice > 0) {
				// For lease when we have a dealer price it includes all discounts.
				leaseAmount = $calcCtrl.dealerPrice;
				discountBeforeTaxTotal = 0;
				discountAfterTaxTotal = 0;
			} else if (!$calcCtrl.vin && $calcCtrl.adminFee) {
				// admin fees are already calculated in sni mode
				// if there's an admin fee, subtract it to discounts before tax
				discountBeforeTaxTotal -= $calcCtrl.adminFee;
			}

			if ($calcCtrl.leaseTerm < 0) {
				$calcCtrl.leaseTerm = lease.terms[$calcCtrl.annualMileageChoice].terms.length - 1;
			}

			// subtract the dealer delta from the discounts (if the delta is negative, this will increase the discount)
			discountBeforeTaxTotal -= ($calcCtrl.cashDealerDelta || 0);

			// this is the index of the selected term (ie: 3)
			let leaseTerm = $calcCtrl.leaseTerm;

			let leaseInterestRate = lease.terms[$calcCtrl.annualMileageChoice].terms[leaseTerm];
			let paymentLease = null;

			if (leaseInterestRate
				&& leaseInterestRate.rate) {
				let leaseData = {
					'debug': 'best-payment',
					'amount': leaseAmount,
					'gkrp': $calcCtrl.gkrp,
					'interestRate': leaseInterestRate.rate,
					'term': leaseInterestRate.duration,
					'residual': leaseInterestRate.residual,
					'downpayment': $calcCtrl.downPayment,
					'tradeInValue': $calcCtrl.tradeValue,
					'oweOnTrade': $calcCtrl.tradeOwed,
					'nbOfPaymentPerYear': $calcCtrl.paymentFrequencies[$calcCtrl.paymentFrequency].payments,
					'discountBeforeTax': discountBeforeTaxTotal,
					'discountAfterTax': discountAfterTaxTotal
				};

				paymentLease = calculatorService.calculateLeasePayment(leaseData, false);
			}
			return paymentLease;
		};

		/**
		 * Calculate payment for a term, program and incentive
		 * Used for : find best payment finance program
		 *
		 * @param finance Finance program
		 * @returns payment for a finance program
		 */
		$calcCtrl.calculateFinanceProgramPaymentPerTerm = (finance) => {
			let amount = $calcCtrl.calculateBaseAmount();

			// admin fees are already calculated in sni mode
			if (!$calcCtrl.vin && $calcCtrl.adminFee) {
				// admin fees are the dealer charge added when the build and price is embedded has a frame in a dealer site
				amount += $calcCtrl.adminFee;
			}

			let discountBeforeTaxTotal = $calcCtrl.sumCashIncentives(finance.incentives, false);
			let discountAfterTaxTotal = $calcCtrl.sumCashIncentives(finance.incentives, true);
			if ($calcCtrl.dealerPrice > 0) {
				amount = $calcCtrl.dealerPrice;
				discountBeforeTaxTotal = 0;
				discountAfterTaxTotal = 0;
			}

			// dealer delta is a difference (either discount or fee)
			amount += (finance.dealerDelta || 0);

			// assing initial finance payment to null to be able to filter out invalid programs
			let financePayment = null;

			// use the finance term currently selected by the user
			let financeTerm = $calcCtrl.financeTerm;
			let financeInterestRate = finance.terms[financeTerm];

			if(financeInterestRate) {
				let financeData = {
					'debug': 'best-payment',
					'amount': amount,
					'interestRate': financeInterestRate.rate,
					'term': financeInterestRate.duration,
					'downpayment': $calcCtrl.downPayment,
					'tradeInValue': $calcCtrl.tradeValue,
					'oweOnTrade': $calcCtrl.tradeOwed,
					'nbOfPaymentPerYear': $calcCtrl.paymentFrequencies[$calcCtrl.paymentFrequency].payments,
					'discountBeforeTax': discountBeforeTaxTotal,
					'discountAfterTax': discountAfterTaxTotal
				};

				let paymentFinanceObject = calculatorService.calculateFinancePayment(financeData, false);
				if(paymentFinanceObject && paymentFinanceObject.hasOwnProperty('payment')) {
					financePayment = paymentFinanceObject.payment;
				}
			}
			return financePayment;
		};

		/**
		 * Get the current applicable finance program
		 */
		$calcCtrl.selectFinanceProgram = () => {
			let result = null;

			// only apply best rate / payment toggle if we have alternative finance
			if ($calcCtrl.pricing.altFinance) {
				if ($calcCtrl.useBestRate) {
					//FIXME RM: in this case, the duration and rate of the best rate should be known by the calculator
					result = $calcCtrl.pricing.altFinance;
				} else {
					// find the program with the lowest payment between finance and alt finance
					result = $calcCtrl.selectBestPaymentFinanceProgram();
				}
			} else {
				result = $calcCtrl.pricing.finance;
			}
			return result;
		};

		/**
		 * Get the current applicable lease program
		 */
		$calcCtrl.selectLeaseProgram = () => {
			let result = null;

			// there are both a lease AND an alternative lease program
			if ($calcCtrl.pricing.altLease
				&& $calcCtrl.pricing.lease) {
				// select the program with the lowest payment
				result = $calcCtrl.selectBestPaymentLeaseProgram();
			} else if ($calcCtrl.pricing.altLease) {
				// there's only an alternative lease program, use it
				result = $calcCtrl.pricing.altLease;
			} else {
				// there's only a lease program, use it
				result = $calcCtrl.pricing.lease;
			}
			return result;
		};

		/**
		 * Get the finance program with the lowest payment
		 * Compare payments on a cent value
		 * If both payments are equal, the cash program is selected.
		 */
		$calcCtrl.selectBestPaymentFinanceProgram = () => {
			let result = null;

			let programs = [];
			if ($calcCtrl.pricing.finance) {
				programs.push($calcCtrl.pricing.finance);
			}
			if ($calcCtrl.pricing.altFinance) {
				programs.push($calcCtrl.pricing.altFinance);
			}

			// only calculate if we have multiple programs
			if (programs.length === 1) {
				result = programs[0];
			} else {
				let payment = null;
				programs.forEach(financeProgram => {
					let programPayment = $calcCtrl.calculateFinanceProgramPaymentPerTerm(financeProgram);
					if (typeof programPayment == 'number') {
						if (payment == null) {
							payment = programPayment;
							result = financeProgram;
						} else if (programPayment === payment) {
							// both programs have same payment, use the cash program
							result = $calcCtrl.pricing.finance;
						} else if (programPayment < payment) {
							payment = programPayment;
							result = financeProgram;
						}
					}
				});
			}
			return result;
		};

		/**
		 * Get the lease program with the lowest payment
		 * Compare payments on a cent value
		 * If both payments are equal, the cash program is selected.
		 */
		$calcCtrl.selectBestPaymentLeaseProgram = () => {
			let result = null;

			let programs = [];
			if ($calcCtrl.pricing.lease) {
				programs.push($calcCtrl.pricing.lease);
			}
			if ($calcCtrl.pricing.altLease) {
				programs.push($calcCtrl.pricing.altLease);
			}

			if (programs.length === 1) {
				result = programs[0];
			} else {
				let payment = null;
				programs.forEach(leaseProgram => {
					let programPayment = $calcCtrl.calculateLeasePaymentPerTerm(leaseProgram);
					if (programPayment) {
						let numberPayment = Number(programPayment.payment);
						if (!payment) {
							payment = numberPayment;
							result = leaseProgram;
						} else if (numberPayment === payment) {
							// both programs have same payment, use cash
							result = $calcCtrl.pricing.lease;
						} else if (numberPayment < payment) {
							payment = numberPayment;
							result = leaseProgram;
						}
					}
				});
			}

			return result;
		};

		$calcCtrl.calculateBaseAmount = (type) => {
			const { basePrice, freight, acTax, greenLevy, luxuryTax = 0, options } = $calcCtrl;

			return basePrice + freight + acTax + greenLevy + luxuryTax + options;
		}

		$calcCtrl.calculatePayments = () => {
			if ($calcCtrl.basePrice && $calcCtrl.basePrice > 0) {
				let baseAmount = $calcCtrl.calculateBaseAmount();

				// wipe finance before calculating
				let defaultFinancePayment = 0;
				let defaultLeasePayment = 0;
				let financeTerm = null;
				let financeInterestRate = null;
				let financeNet = null;
				let financeDiscounts = null;
				let currentTaxRate = !!$calcCtrl.includeSalesTax ? 1.0 : $calcCtrl.currentTaxRate;
				$calcCtrl.paymentFinanceWithTax = null;
				$calcCtrl.paymentFinance = null;

				// Calculate finance
				if ($calcCtrl.financePrograms
					&& $calcCtrl.financeInterestRate.length > 0
					&& $calcCtrl.financeInterestRate[$calcCtrl.financeTerm]) {

					let financeAmount = baseAmount
						+ ($calcCtrl.financeDealerDelta || 0);// dealers can add or subtract an amount

					// admin fees are already calculated in sni mode
					if (!$calcCtrl.vin && $calcCtrl.adminFee) {
						financeAmount += ($calcCtrl.adminFee || 0); // admin fees are added dealer charge
					}

					$calcCtrl.financeDiscountTotalBeforeTax = $calcCtrl.sumCashIncentives($calcCtrl.financeIncentives, false);
					$calcCtrl.financeDiscountTotalAfterTax = $calcCtrl.sumCashIncentives($calcCtrl.financeIncentives, true);

					let financeDiscountBeforeTaxTotal = $calcCtrl.financeDiscountTotalBeforeTax;
					let financeDiscountAfterTaxTotal = $calcCtrl.financeDiscountTotalAfterTax;

					if ($calcCtrl.dealerPrice > 0) {
						// if there's a dealer price, the discounts are not added
						financeAmount = $calcCtrl.dealerPrice;
						financeDiscountBeforeTaxTotal = 0;
						financeDiscountAfterTaxTotal = 0;
					}

					// keep the finance discount and net amount
					financeDiscounts = financeDiscountBeforeTaxTotal + financeDiscountAfterTaxTotal;
					financeNet = financeAmount - financeDiscounts;

					financeTerm = $calcCtrl.financeInterestRate[$calcCtrl.financeTerm].key;

					// recover current program rate
					let financeTermObject = $calcCtrl.financePrograms.filter(term => Number(term.duration) == Number(financeTerm));

					if (financeTermObject && financeTermObject.length > 0) {
						financeInterestRate = financeTermObject[0].rate;

						let financeData = {
							'debug' : 'payment',
							'amount': financeAmount,
							'interestRate': financeTermObject[0].rate,
							'term': financeTermObject[0].duration,
							'downpayment': $calcCtrl.downPayment,
							'tradeInValue': $calcCtrl.tradeValue,
							'oweOnTrade': $calcCtrl.tradeOwed,
							'nbOfPaymentPerYear': $calcCtrl.paymentFrequencies[$calcCtrl.paymentFrequency].payments,
							'discountBeforeTax': financeDiscountBeforeTaxTotal,
							'discountAfterTax': financeDiscountAfterTaxTotal
						};

						if ($calcCtrl.pricing.altFinance) {
							let program = $calcCtrl.selectBestPaymentFinanceProgram();
							if (program) {
								if ($calcCtrl.sumCashIncentives(program.incentives, false))
									$calcCtrl.showRateSwitch = true;
								else {
									$calcCtrl.showRateSwitch = false;
									$calcCtrl.useBestRate = false;
								}
							}
						}

						$calcCtrl.setCurrentTaxRate(financeAmount);
						$calcCtrl.paymentFinance = calculatorService.calculateFinancePayment(financeData);
						// do not calculate taxes if we can't
						if ($calcCtrl.salesTax) {
							$calcCtrl.paymentFinanceWithTax = calculatorService.calculateFinancePaymentWithTax(financeData, $calcCtrl.salesTax, $calcCtrl.provinceCode, $calcCtrl.language);
							//QFGBFI-1286 we need to calculate tax value without tradeIn to display tax reduction
							financeData['tradeInValue'] = 0;
							financeData['oweOnTrade'] = 0;
							$calcCtrl.defaultPaymentFinanceWithTax = calculatorService.calculateFinancePaymentWithTax(financeData, $calcCtrl.salesTax, $calcCtrl.provinceCode, $calcCtrl.language);
							$calcCtrl.defaultPaymentFinanceWithTax.taxReduction = $calcCtrl.defaultPaymentFinanceWithTax.salesTaxAmount - $calcCtrl.paymentFinanceWithTax.salesTaxAmount;
						} else {
							$calcCtrl.defaultPaymentFinanceWithTax = null;
							$calcCtrl.paymentFinanceWithTax = null;
						}

						//for shop by payment, we need to calculate a payment without downpayment
						financeData.debug = 'shop-by-payment';
						financeData['downpayment'] = 0;
						financeData['tradeInValue'] = 0;
						financeData['oweOnTrade'] = 0;
						defaultFinancePayment = calculatorService.calculateFinancePayment(financeData);
					}
				}

				// wipe lease values before we calculate them
				let leaseInterestRate = null;
				let leaseTerm = null;
				let leaseMileage = null;
				let leaseNet = null;
				let leaseDiscounts = null;
				$calcCtrl.paymentLease = null;
				$calcCtrl.paymentLeaseWithTax = null;


				if ($calcCtrl.leasePrograms
					&& $calcCtrl.leaseInterestRate.length > 0
					&& $calcCtrl.leaseInterestRate[$calcCtrl.leaseTerm]) {

					// Calculate lease
					let leaseAmount = baseAmount;

					$calcCtrl.leaseDiscountTotalBeforeTax = $calcCtrl.sumCashIncentives($calcCtrl.leaseIncentives, false);
					$calcCtrl.leaseDiscountTotalAfterTax = $calcCtrl.sumCashIncentives($calcCtrl.leaseIncentives, true);

					let leaseDiscountBeforeTaxTotal = $calcCtrl.leaseDiscountTotalBeforeTax;
					let leaseDiscountAfterTaxTotal = $calcCtrl.leaseDiscountTotalAfterTax;

					if ($calcCtrl.dealerPrice > 0) { //SNI - we don't apply discounts because dealer price already has it.
						// For lease when we have a dealer price it includes all discounts.
						leaseAmount = $calcCtrl.dealerPrice;
						leaseDiscountBeforeTaxTotal = 0;
						leaseDiscountAfterTaxTotal = 0;
					} else if (!$calcCtrl.vin && $calcCtrl.adminFee) {
						// admin fees are already calculated in sni mode
						// if there's an admin fee, subtract it to discounts before tax
						leaseDiscountBeforeTaxTotal -= $calcCtrl.adminFee;
					}

					if ($calcCtrl.dealerPrice == 0) { //BP - we don't have dealer price so we apply discounts.
						// subtract the dealer delta from the discounts (if the delta is negative, this will increase the discount)
						leaseDiscountBeforeTaxTotal -= ($calcCtrl.cashDealerDelta || 0);
					}

					leaseInterestRate = $calcCtrl.leaseInterestRate[$calcCtrl.leaseTerm].value;
					leaseTerm = $calcCtrl.leaseInterestRate[$calcCtrl.leaseTerm].key;

					// keep the lease discount and net amount
					leaseDiscounts = leaseDiscountBeforeTaxTotal + leaseDiscountAfterTaxTotal;
					// keep the lease net amount (does not include down-payment or trade-in)
					leaseNet = leaseAmount - leaseDiscounts;

					// recover current program rate
					let leaseTermObject = $calcCtrl.leasePrograms[$calcCtrl.annualMileageChoice].terms.filter(term => Number(term.duration) == Number(leaseTerm));

					if (leaseTermObject.length > 0) {
						leaseMileage = $calcCtrl.leasePrograms[$calcCtrl.annualMileageChoice].km;
						let leaseData = {
							'debug' : 'payment',
							'amount': leaseAmount,
							'gkrp': $calcCtrl.gkrp,
							'interestRate': leaseTermObject[0].rate,
							'term': leaseTermObject[0].duration,
							'residual': leaseTermObject[0].residual,
							'downpayment': $calcCtrl.downPayment,
							'tradeInValue': $calcCtrl.tradeValue,
							'oweOnTrade': $calcCtrl.tradeOwed,
							'nbOfPaymentPerYear': $calcCtrl.paymentFrequencies[$calcCtrl.paymentFrequency].payments,
							'discountBeforeTax': leaseDiscountBeforeTaxTotal,
							'discountAfterTax': leaseDiscountAfterTaxTotal
						};

						$calcCtrl.paymentLease = calculatorService.calculateLeasePayment(leaseData);
						$calcCtrl.setCurrentTaxRate(leaseAmount);
						if ($calcCtrl.salesTax) {
							$calcCtrl.paymentLeaseWithTax = calculatorService.calculateLeasePaymentWithTax(leaseData, $calcCtrl.salesTax, $calcCtrl.provinceCode, $calcCtrl.language);
							//QFGBFI-1286 we need to calculate tax value without tradeIn to display tax reduction
							leaseData['tradeInValue'] = 0;
							leaseData['oweOnTrade'] = 0;
							$calcCtrl.defaultPaymentLeaseWithTax = calculatorService.calculateLeasePaymentWithTax(leaseData, $calcCtrl.salesTax, $calcCtrl.provinceCode, $calcCtrl.language);
							$calcCtrl.defaultPaymentLeaseWithTax.taxReduction = $calcCtrl.defaultPaymentLeaseWithTax.salesTaxAmount - $calcCtrl.paymentLeaseWithTax.salesTaxAmount;
						} else {
							$calcCtrl.defaultPaymentLeaseWithTax;
							$calcCtrl.paymentLeaseWithTax = null;
						}

						//for shop by payment, we need to calculate a payment without downpayment
						leaseData.debug = 'shop-by-payment';
						leaseData['downpayment'] = 0;
						leaseData['tradeInValue'] = 0;
						leaseData['oweOnTrade'] = 0;
						defaultLeasePayment = calculatorService.calculateLeasePayment(leaseData);
					}
				}

				// calculate cash net amount
				let cashNetAmount = baseAmount;

				$calcCtrl.cashDiscountTotalBeforeTax = $calcCtrl.sumCashIncentives($calcCtrl.cashIncentives, false) || 0;
				$calcCtrl.cashDiscountTotalAfterTax = $calcCtrl.sumCashIncentives($calcCtrl.cashIncentives, true) || 0;

				let cashDiscountBeforeTaxTotal = $calcCtrl.cashDiscountTotalBeforeTax;
				let cashDiscountAfterTaxTotal = $calcCtrl.cashDiscountTotalAfterTax;
				let cashDiscountsTotal = cashDiscountBeforeTaxTotal + cashDiscountAfterTaxTotal;

				// when we have a dealer price it includes all discounts.
				if ($calcCtrl.dealerPrice > 0) {
					cashNetAmount = $calcCtrl.dealerPrice;
					cashDiscountBeforeTaxTotal = 0;
					cashDiscountAfterTaxTotal = 0;
				}

				// admin fees are already calculated in sni mode
				if (!$calcCtrl.vin && $calcCtrl.adminFee) {
					cashNetAmount += ($calcCtrl.adminFee || 0); // admin fees are added dealer charge
				}

				cashNetAmount += ($calcCtrl.cashDealerDelta || 0); // dealer delta (can be positive or negative)

				// warning: when we actually implement or programs, we may want to calculate actual finance and lease payments
				// we are only using the finance calculator to calculate taxes
				$calcCtrl.cashData = null;
				let cashData = {
					'amount': cashNetAmount,
					'downpayment': $calcCtrl.downPayment,
					'tradeInValue': $calcCtrl.tradeValue,
					'oweOnTrade': $calcCtrl.tradeOwed,
					'discountBeforeTax': cashDiscountBeforeTaxTotal,
					'discountAfterTax': cashDiscountAfterTaxTotal
				};

				$calcCtrl.setCurrentTaxRate(cashNetAmount);
				// calculate sales taxes on cash (if possible)
				if ($calcCtrl.salesTax) {
					$calcCtrl.cashTaxes = calculatorService.calculateCashTaxes(cashData, $calcCtrl.salesTax, $calcCtrl.provinceCode, $calcCtrl.language);
					//QFGBFI-1286 we need to calculate tax value without tradeIn to display tax reduction
					cashData['tradeInValue'] = 0
					cashData['oweOnTrade'] = 0
					$calcCtrl.defaultCashTaxes = calculatorService.calculateCashTaxes(cashData, $calcCtrl.salesTax, $calcCtrl.provinceCode, $calcCtrl.language);
					$calcCtrl.defaultCashTaxes.taxReduction = $calcCtrl.defaultCashTaxes.salesTaxAmount - $calcCtrl.cashTaxes.salesTaxAmount
				} else {
					$calcCtrl.defaultCashTaxes = null;
					$calcCtrl.cashTaxes = null;
				}

				let incentives = $calcCtrl.getDiscounts();

				$calcCtrl.currentRates = $calcCtrl.getCurrentRates();
				$calcCtrl.currentIncentives = incentives;

				//shop by payments start
				configService.setPaymentFrequency($calcCtrl.paymentFrequency);
				configService.setDefaultFinancePayment(defaultFinancePayment != null ? defaultFinancePayment.payment : null);
				configService.setDefaultLeasePayment(defaultLeasePayment != null ? defaultLeasePayment.payment : null);
				configService.setDefaultNetAmount($calcCtrl.calculateShopByPaymentNetAmount());
				//shop by payments end

				$calcCtrl.updateNetAmount();
				initEmptyField($calcCtrl);

				// wait until we are done to broadcast information
				// this information is used by the director component
				let updatePaymentData = {
					'cashNetAmount': $calcCtrl.calculateCashNetAmount(!!$calcCtrl.includeSalesTax), // the director wants the un-adjusted cash net amount
					'netAmount': cashNetAmount - cashDiscountsTotal, // the alfa romeo director wants the un-adjusted cash net amount
					'currentRates': $calcCtrl.currentRates,
					'frequency': $calcCtrl.paymentFrequencies && $calcCtrl.paymentFrequencies[$calcCtrl.paymentFrequency]
						? $calcCtrl.paymentFrequencies[$calcCtrl.paymentFrequency].key
						: null,
					'discounts': incentives,
					'hasHybridIncentives': $calcCtrl.hasHybridIncentives,
					'disclaimer': $calcCtrl.disclaimer,
					'totalDiscount': $calcCtrl.sumIncentives(), // the sum of the incentives for the currently selected tab (cash/finance/lease)
					'downPayment': $calcCtrl.downPayment,
					'tradeInValue': $calcCtrl.tradeValue,
					'tradeOwed': $calcCtrl.tradeOwed,
					'useBestRate': $calcCtrl.useBestRate, // true or false
					'hasAltFinance': $calcCtrl.pricing && !!$calcCtrl.pricing.altFinance,
					'hasAltLease': $calcCtrl.hasAltLease,
					'hasLease': $calcCtrl.hasLease,
					'financeMode': $calcCtrl.activeTab, // can be either cash / finance / lease
					'options': $calcCtrl.options
				};
				if ($calcCtrl.paymentFinance) {
					updatePaymentData.financePayment = $calcCtrl.paymentFinance.payment;
					updatePaymentData.financeRate = financeInterestRate;
					updatePaymentData.financeTerm = financeTerm;
					updatePaymentData.financeNet = financeNet;
					updatePaymentData.financeDiscounts = financeDiscounts;
				}
				if ($calcCtrl.paymentLease) {
					updatePaymentData.leasePayment = $calcCtrl.paymentLease.payment;
					updatePaymentData.leaseResidualValue = $calcCtrl.paymentLease.residual;
					updatePaymentData.leaseTerm = leaseTerm;
					updatePaymentData.leaseInterestRate = leaseInterestRate;
					updatePaymentData.leaseMileage = leaseMileage;
					updatePaymentData.leaseNet = leaseNet;
					updatePaymentData.leaseDiscounts = leaseDiscounts;
				}

				// if the user manages to get zero cost
				if ($calcCtrl.paymentFinance && $calcCtrl.paymentFinance.netzero) {
					updatePaymentData.financeNetZero = true;
				}

				// we don't want to ever broadcast anything with zero value
				if (updatePaymentData.cashNetAmount
					&& updatePaymentData.cashNetAmount > 0) {
					$rootScope.$broadcast('calculator:update-payment', updatePaymentData);

					// execute call-back if necessary
					if ($calcCtrl.paymentUpdateCallback) {
						try {
							$calcCtrl.paymentUpdateCallback(updatePaymentData);
						} catch (error) {
							$exceptionHandler(error);
						}
					}
				}

				// This is only used by the apply for financing component
				let financeRateChangedData = {
					financeInterestRate: financeInterestRate,
					monthlyEstimate: $calcCtrl.paymentFinance != null ? $calcCtrl.paymentFinance.payment : null
				};

				// do not broadcast unless the payment is positive
				if (financeRateChangedData.monthlyEstimate
					&& financeRateChangedData.monthlyEstimate > 0) {
					$rootScope.$broadcast('calculator:finance-rate-changed', financeRateChangedData);
				}
			}
		};

		/**
		 * return a before and after taxes object that sum up incentives amounts for active tab
		 */
		$calcCtrl.getCurrentIncentivesTotals = () => {
			let incentives = {};
			let currentIncentives = $calcCtrl.getCurrentIncentivesObject();

			incentives.beforeTax = $calcCtrl.sumCashIncentives(currentIncentives, false);
			incentives.afterTax = $calcCtrl.sumCashIncentives(currentIncentives, true);
			return incentives;
		};

		/**
		 * get all incentives for selected tab
		 * @returns {Array}
		 */
		$calcCtrl.getCurrentIncentivesObject = () => {
			let currentIncentives = [];
			if($calcCtrl.activeTab == 'cash') {
				currentIncentives = $calcCtrl.cashIncentives;
			} else if($calcCtrl.activeTab == 'finance') {
				currentIncentives = $calcCtrl.financeIncentives;
			} else if($calcCtrl.activeTab == 'lease') {
				currentIncentives = $calcCtrl.leaseIncentives;
			}
			return currentIncentives;
		};

		/**
		 * get all before taxes incentives for selected tab
		 * @returns {Array}
		 */
		$calcCtrl.getCurrentBeforeTaxesIncentivesObject = () => {
			return $calcCtrl.getCurrentIncentivesObject().filter(function (incentive) {
				return (incentive.beforeTax);
			});
		};

		/**
		 * get all after taxes incentives for selected tab
		 * @returns {Array}
		 */
		$calcCtrl.getCurrentAfterTaxesIncentivesObject = () => {
			return $calcCtrl.getCurrentIncentivesObject().filter(function (incentive) {
				return (!incentive.beforeTax);
			});
		};

		/**
		 * return incentives amount for a discount type
		 *
		 * all : used without taxes calculation
		 * before/after : used when taxes are calculate
		 *
		 * @param discountType
		 * @returns {*}
		 */
		$calcCtrl.getCurrentIncentivesTotal = (discountType) => {
			const incentives = $calcCtrl.getCurrentIncentivesTotals();
			let total = 0;
			let taxRate = $calcCtrl.includeSalesTax ? 1.0 : $calcCtrl.currentTaxRate;
			let incentivesBeforeTax = incentives.beforeTax;
			let incentivesAfterTax = incentives.afterTax;


			switch (discountType) {
				case 'all':
					total = incentivesBeforeTax + incentivesAfterTax;
					$rootScope.$broadcast('calculator:get-current-icentives-total', total);
					break;
				case 'before':
					total = incentivesBeforeTax;
					break;
				case 'after':
					total = incentivesAfterTax;
					break;
				default:
					break;
			}
			return total;
		};

		$calcCtrl.getCurrentRates = () => {
			let result = {};
			if ($calcCtrl.financePrograms &&
				$calcCtrl.financeInterestRate &&
				$calcCtrl.financeTerm !== undefined &&
				$calcCtrl.financeTerm >= 0 &&
				$calcCtrl.financeInterestRate.length >= $calcCtrl.financeTerm) {
				// determine the currently selected finance term duration (the key is the string value of the duration ie: 60)
				const financeTermDuration = Number($calcCtrl.financeInterestRate[$calcCtrl.financeTerm].key);
				const financeProgram = $calcCtrl.financePrograms.find(program => Number(program.duration) === financeTermDuration);
				if (financeProgram) {
					result.finance = {
						apr: financeProgram.rate,
						eir: financeProgram.eir,
						duration: financeProgram.duration
					};
				}
			}

			if ($calcCtrl.leasePrograms &&
				$calcCtrl.leaseInterestRate &&
				$calcCtrl.leaseTerm !== undefined &&
				$calcCtrl.leaseTerm >= 0 &&
				$calcCtrl.leaseInterestRate.length >= $calcCtrl.leaseTerm) {

				// get the program that corresponds to the current mileage
				let leaseTerms = $calcCtrl.leasePrograms[$calcCtrl.annualMileageChoice];
				if(leaseTerms && leaseTerms.terms) {
					// determine the currently selected lease term duration (the key is the string value of the duration ie: 60)
					const leaseTermDuration = Number($calcCtrl.leaseInterestRate[$calcCtrl.leaseTerm].key);
					// get the term with the same duration as the one selected
					const leaseTerm = leaseTerms.terms.find(term => Number(term.duration) === leaseTermDuration);
					if (leaseTerm) {
						result.lease = {
							apr: leaseTerm.rate,
							eir: leaseTerm.eir,
							duration: leaseTerm.duration
						};
					}
				}
			}
			return result;
		};

		$scope.tradeInValueTriggeredOnBlur = function ($event) {
			$calcCtrl.tradeOwedTriggered = $event.target.value > 0;
		};

		$calcCtrl.tradeInValueTriggered = () => {
			$calcCtrl.tradeOwedTriggered = $calcCtrl.tradeValue > 0;
		};

		$calcCtrl.sumIncentives = () => {
			return $calcCtrl.discountBeforeTaxTotal + $calcCtrl.discountAfterTaxTotal;
		};

		/**
		 * new calculator : return true if discount amount is greater than 0
		 * legacy calculator : return true if array of incentivesd is greater than 0
		 */
		$calcCtrl.hasIncentives = (discount) => {
			return (discount && discount > 0) || (discount && discount.length > 0);
		};

		$calcCtrl.accordionStyle = () => {
			if ($('.C_CAL_TAB-flex-zone').hasClass('C_CAL_TAB-active-accordion')) {
				$('.C_CAL_TAB-flex-zone').removeClass('C_CAL_TAB-active-accordion');
				$('.accordion-arrow').removeClass('fcaicon-chevron-up');
				$('.accordion-arrow').addClass('fcaicon-chevron-down');
				$('.C_CAL_TAB-input-list').toggleClass('toggle-sliders');
			} else {
				$('.C_CAL_TAB-flex-zone').addClass('C_CAL_TAB-active-accordion');
				$('.accordion-arrow').removeClass('fcaicon-chevron-down');
				$('.accordion-arrow').addClass('fcaicon-chevron-up');
				$('.C_CAL_TAB-input-list').toggleClass('toggle-sliders');
			}
		};

		// This allows us to use the annualMileageChoice numerical value with the toggle checkbox by converting
		// back and forth from number to boolean
		$calcCtrl.mileageToggleGetterSetter = (value) => {
			if (angular.isDefined(value)) {
				// Update model
				if (value) {
					$calcCtrl.mileageToggle(1);
				} else {
					$calcCtrl.mileageToggle(0);
				}
			} else {
				// Return value
				return $calcCtrl.annualMileageChoice === 0 ? false : true;
			}
		};

		// Change value to simulate a toggle with the slider
		$calcCtrl.mileageToggle = (val) => {
			$calcCtrl.annualMileageChoice = val;
			$calcCtrl.calcUpdatedValues();
		};

		$calcCtrl.returnNetAmount = (type) => {
			let netAmount = 0;
			if (type === 'cash') {
				netAmount = $calcCtrl.calculateCashNetAmount(!!$calcCtrl.includeSalesTax);
			} else if (type === 'finance') {
				netAmount = $calcCtrl.calculateFinanceNetAmount(!!$calcCtrl.includeSalesTax);
			} else if (type === 'lease') {
				netAmount = $calcCtrl.calculateLeaseNetAmount(!!$calcCtrl.includeSalesTax);
			}

			// Return 0 if netAmount is negative
			return netAmount > 0 ? netAmount : 0;
		};

		/**
		 * This is the calculated cash net amount.
		 * It's the equivalent of the monroney less any adjustments.
		 */
		$calcCtrl.calculateCashNetAmount = (includeSalesTax) => {
			let netAmount = 0;
			let taxAmount = 0;
			let currentTaxRate = $calcCtrl.currentTaxRate ? $calcCtrl.currentTaxRate : 1.0;

			if (includeSalesTax && $calcCtrl.cashTaxes != null) {
				taxAmount = ($calcCtrl.cashTaxes.pstTaxAmount || 0)
					+ ($calcCtrl.cashTaxes.gstHstTaxAmount || 0)
					- ($calcCtrl.cashTaxes.taxReduction || 0)
			}
			netAmount = + $calcCtrl.basePrice
				+ $calcCtrl.freight
				+ $calcCtrl.acTax
				+ $calcCtrl.greenLevy
				+ ($calcCtrl.luxuryTax || 0)
				+ $calcCtrl.options
				- $calcCtrl.downPayment
				- $calcCtrl.tradeValue
				+ $calcCtrl.tradeOwed
				- ($calcCtrl.cashDiscountTotalBeforeTaxes || 0)
				- ($calcCtrl.cashDiscountTotalAfterTaxes || 0)
				+ ($calcCtrl.cashDealerDelta || 0)
				+ taxAmount;

			// admin fees are already calculated in sni mode
			if (!$calcCtrl.vin && $calcCtrl.adminFee) {
				netAmount += ($calcCtrl.adminFee || 0); // admin fees are added dealer charge
			}

			return netAmount > 0 ? netAmount : 0;
		};

		$calcCtrl.calculateFinanceNetAmount = (includeSalesTax) => {
			let netAmount = 0;
			let taxAmount = 0;
			let currentTaxRate = $calcCtrl.currentTaxRate ? $calcCtrl.currentTaxRate : 1.0;
			let financeDiscountTotalBeforeTax = ($calcCtrl.financeDiscountTotalBeforeTax || 0);
			let financeDiscountTotalAfterTax = ($calcCtrl.financeDiscountTotalAfterTax || 0);
			if($calcCtrl.buildAndPriceApplyForFinancingDiscountTotalBeforeTax) {
				financeDiscountTotalBeforeTax = $calcCtrl.buildAndPriceApplyForFinancingDiscountTotalBeforeTax;
			}

			if($calcCtrl.buildAndPriceApplyForFinancingDiscountTotalAfterTax) {
				financeDiscountTotalAfterTax = $calcCtrl.buildAndPriceApplyForFinancingDiscountTotalAfterTax;
			}

			if (includeSalesTax && $calcCtrl.paymentFinanceWithTax != null) {
				taxAmount = ($calcCtrl.paymentFinanceWithTax.pstTaxAmount || 0)
					+ ($calcCtrl.paymentFinanceWithTax.gstHstTaxAmount || 0)
			}
			netAmount = $calcCtrl.basePrice
				+ $calcCtrl.freight
				+ $calcCtrl.acTax
				+ $calcCtrl.greenLevy
				+ ($calcCtrl.luxuryTax || 0)
				+ $calcCtrl.options
				- $calcCtrl.downPayment
				- $calcCtrl.tradeValue
				+ $calcCtrl.tradeOwed
				- ($calcCtrl.financeDiscountTotalBeforeTax || 0)
				- ($calcCtrl.financeDiscountTotalAfterTax || 0)
				+ ($calcCtrl.financeDealerDelta || 0)
				+ taxAmount;

			// admin fees are already calculated in sni mode
			if (!$calcCtrl.vin && $calcCtrl.adminFee) {
				netAmount += ($calcCtrl.adminFee || 0); // admin fees are added dealer charge
			}

			return netAmount > 0 ? netAmount : 0;
		};

		$calcCtrl.calculateShopByPaymentNetAmount = () => {
			let netAmount;

			let financeDiscountTotalBeforeTax = $calcCtrl.financeDiscountTotalBeforeTax;
			let financeDiscountTotalAfterTax = $calcCtrl.financeDiscountTotalAfterTax;
			if($calcCtrl.buildAndPriceApplyForFinancingDiscountTotalBeforeTax) {
				financeDiscountTotalBeforeTax = $calcCtrl.buildAndPriceApplyForFinancingDiscountTotalBeforeTax;
			}

			if($calcCtrl.buildAndPriceApplyForFinancingDiscountTotalAfterTax) {
				financeDiscountTotalAfterTax = $calcCtrl.buildAndPriceApplyForFinancingDiscountTotalAfterTax;
			}

			netAmount = $calcCtrl.basePrice + $calcCtrl.freight + $calcCtrl.acTax + $calcCtrl.greenLevy
				+ $calcCtrl.options
				- financeDiscountTotalBeforeTax
				- financeDiscountTotalAfterTax;

			if($calcCtrl.adminFee) {
				// admin fees are the dealer charge added when the build and price is embedded has a frame in a dealer site
				netAmount += $calcCtrl.adminFee;
			}

			return netAmount > 0 ? netAmount : 0;
		};

		$calcCtrl.calculateLeaseNetAmount = (includeSalesTax) => {
			let netAmount = 0;
			let taxAmount = 0;
			let currentTaxRate = $calcCtrl.currentTaxRate ? $calcCtrl.currentTaxRate : 1.0;

			if (includeSalesTax && $calcCtrl.paymentLeaseWithTax != null) {
				taxAmount = ($calcCtrl.paymentLeaseWithTax.pstTaxAmount || 0)
					+ ($calcCtrl.paymentLeaseWithTax.gstHstTaxAmount || 0)
			}
			netAmount = $calcCtrl.options
				- ($calcCtrl.downPayment + $calcCtrl.tradeValue - $calcCtrl.tradeOwed)
				- ($calcCtrl.leaseDiscountTotalBeforeTax || 0)
				- ($calcCtrl.leaseDiscountTotalAfterTax || 0)
				+ ($calcCtrl.leaseDealerDelta || 0)
				+ taxAmount
				+ $calcCtrl.basePrice + $calcCtrl.freight + $calcCtrl.acTax + $calcCtrl.greenLevy;

			return netAmount > 0 ? netAmount : 0;
		};

		$calcCtrl.returnNetDealerPrice = () => {
			let netDealerPrice = $calcCtrl.dealerPrice - ($calcCtrl.downPayment + $calcCtrl.tradeValue - $calcCtrl.tradeOwed);
			// Return 0 if netAmount is negative
			return netDealerPrice > 0 ? netDealerPrice : 0;
		};

		/**
		 * input: 12K
		 * output: 12000
		 * @param abbrNum
		 * @returns {number|*}
		 */
		$calcCtrl.displayThousands = (abbrNum) => {
			let result = abbrNum;
			if (result) {
				result = result.length === 3 ? parseInt(result) * 1000 : result;
			}
			return result;
		};

		$calcCtrl.setDefaultMileage = () => {
			const defaultKey = $calcCtrl.defaultMileage;
			const defaultMileage = $calcCtrl.residualList.find(e => e.key === defaultKey);
			const defaultMileageIndex = $calcCtrl.residualList.indexOf(defaultMileage);
			return defaultMileageIndex >= 0 ? defaultMileageIndex : 0;
		};

		$calcCtrl.setDefaultMileageLegacy = () => {
			const defaultKey = $calcCtrl.defaultMileageLegacy;
			const defaultMileage = $calcCtrl.residualList.find(e => e.key === defaultKey);
			const defaultMileageIndex = $calcCtrl.residualList.indexOf(defaultMileage);
			return defaultMileageIndex >= 0 ? defaultMileageIndex : 0;
		};

		/*
		 Filter incentive after and before tax
		 */
		$calcCtrl.getIncentives = (incentives, isAfterTaxes = false) => {
			var result = [];
			if(incentives) {
				// isAfterTaxes is a boolean to specify whether we have the incentives before or after taxes
				result = incentives.filter(incentive => incentive.afterTaxes === isAfterTaxes && incentive.cash);
			}

			return result;
		};

		// Sum one of the numerical values in each object in an array of objects
		$calcCtrl.sumValueInArrayOfObjects = (objects, dataKey) => {
			return objects.reduce((total, object) => {
				return total + parseFloat(object[dataKey])
			}, 0);
		};

		$calcCtrl.setCurrentTaxRate = (amount) => {
			const saleTax = $calcCtrl.salesTax;

			const bracketsSaleTaxesObject = saleTax;

			if (!!bracketsSaleTaxesObject.taxBrackets) {
				for (let y = 0; y < bracketsSaleTaxesObject.taxBrackets.length; y++) {
					let lesserThan = Number.MAX_VALUE;
					let higherThan = Number.MIN_VALUE;
					if (!!bracketsSaleTaxesObject.taxBrackets[y].lesserThan) {
						lesserThan = bracketsSaleTaxesObject.taxBrackets[y].lesserThan;
					}
					if (!!bracketsSaleTaxesObject.taxBrackets[y].higherThan) {
						higherThan = bracketsSaleTaxesObject.taxBrackets[y].higherThan;
					}
					if (lesserThan >= amount && higherThan <= amount) {
						saleTax.taxes = bracketsSaleTaxesObject.taxBrackets[y].taxes;
						break;
					}
				}
			}

			if (!!saleTax && !!saleTax.taxes) {
				let totalTaxReducer = (totalTax, taxObj) => totalTax + parseFloat(taxObj.tax);
				let taxFilter = (taxObj) => taxObj.taxType === 'gstHst' || taxObj.taxType === 'pst';
				let taxes = saleTax.taxes.filter(taxFilter).reduce(totalTaxReducer, 0);
				$calcCtrl.currentTaxRate = 1 + taxes / 100;
			}
		}

		$calcCtrl.sumCashIncentives = (incentives, isAfterTaxes = false) => {
			if (incentives && incentives.length > 0) {
				const incentivesCopy = JSON.parse(JSON.stringify(incentives));
				const taxRate = !!$calcCtrl.includeSalesTax ? 1.0 : $calcCtrl.currentTaxRate * 1.0;

				incentivesCopy.forEach(incentive => {
					incentive.cash = incentive.cash * 1.0;
					if (!incentive.beforeTax && !$calcCtrl.vin && !incentive.cashTaxFree && taxRate > 0) {
						incentive.cash = Math.round(incentive.cash / taxRate);
					}
				});

				let beforeOrAfterTaxesIncentives = isAfterTaxes
					? $calcCtrl.getAfterTaxesIncentives(incentivesCopy)
					: $calcCtrl.getBeforeTaxesIncentives(incentivesCopy);

					return $calcCtrl.sumValueInArrayOfObjects(beforeOrAfterTaxesIncentives, "cash");
			}
			return 0;
		};

		$calcCtrl.getBeforeTaxesIncentives = (incentives) => {
			return incentives.filter(incentive => incentive.beforeTax);
		};

		$calcCtrl.getAfterTaxesIncentives = (incentives) => {
			return incentives.filter(incentive => !incentive.beforeTax);
		};

		$calcCtrl.getDiscounts = () => {
			let incentives = {};
			let taxRate = !!$calcCtrl.includeSalesTax ? 1.0 : $calcCtrl.currentTaxRate;

			if ($calcCtrl.cashIncentives) {
				incentives.cash = {};
				incentives.cash.beforeTax = $calcCtrl.sumCashIncentives($calcCtrl.cashIncentives, false);
				incentives.cash.afterTax = $calcCtrl.sumCashIncentives($calcCtrl.cashIncentives, true);
				incentives.cash.hasDiscount = $calcCtrl.cashIncentives.filter((discount) => discount.governmentRebate == false).length > 0;
				incentives.cash.total = (incentives.cash.beforeTax||0) + (incentives.cash.afterTax||0);
			}
			if ($calcCtrl.financeIncentives) {
				incentives.finance = {};
				incentives.finance.beforeTax = $calcCtrl.sumCashIncentives($calcCtrl.financeIncentives, false);
				incentives.finance.afterTax = $calcCtrl.sumCashIncentives($calcCtrl.financeIncentives, true);
				incentives.finance.hasDiscount = $calcCtrl.financeIncentives.filter((discount) => discount.governmentRebate == false).length > 0;
				incentives.finance.total = (incentives.finance.beforeTax||0) + (incentives.finance.afterTax||0);
			}
			if ($calcCtrl.leaseIncentives) {
				incentives.lease = {};
				incentives.lease.beforeTax = $calcCtrl.sumCashIncentives($calcCtrl.leaseIncentives, false);
				incentives.lease.afterTax = $calcCtrl.sumCashIncentives($calcCtrl.leaseIncentives, true);
				incentives.lease.hasDiscount = $calcCtrl.leaseIncentives.filter((discount) => discount.governmentRebate == false).length > 0;
				incentives.lease.total = (incentives.lease.beforeTax||0) + (incentives.lease.afterTax||0);
			}
			return incentives;
		};


		/**
		 * Takes an object, returns a sorted array of objects containing key and value pairs
		 * @param data
		 * @returns {any[]}
		 */
		$calcCtrl.convertObjectToArray = (data = {}) => {
			let arrayResult = Object.keys(data).map(key => {
				return { key, value: data[key] }
			});

			arrayResult = this.sortKeys(arrayResult);

			return arrayResult;
		};

		/**
		 * Sort a list of object that have an attribute 'key'.
		 * If the key is not completely numeric, all non numeric
		 * characters are be dropped.
		 *
		 * @param list of objects with keys
		 * @returns {*} Sorted List
		 */
		$calcCtrl.sortKeys = (list) => {
			let sortedList = list.sort((a, b) => {
				// Numerical values are compared using the minus to sort
				// in ascending order.
				let compared = this.parseKey(a.key) - this.parseKey(b.key);
				return compared;
			});
			return sortedList;
		};


		/**
		 * Converts a key value to a float version.
		 * @param key Value containing mostly numbers but
		 * can also have some alpha chars. These are removed
		 * before conversion.
		 *
		 * @returns {number} Converted Float value of the Key.
		 */
		$calcCtrl.parseKey = (key) => {
			let numericKey = key.replace(/\D/g,'');
			let floatkey = parseFloat(numericKey);
			return floatkey;
		};

		// Scroll to See Options
		$calcCtrl.animateScrollOnBtnClick = (elem) => {
			$("html, body").animate({ scrollTop: $(elem).offset().top }, 300);
			if ($(elem).tabIndex == undefined) {
				$(elem).attr('tabindex', 0);
			}
			$(elem).focus();
		};

		$calcCtrl.tradeInEstimatorOverlay = () => {
			$rootScope.$broadcast('build-and-price:initialize-trade-in-estimator', {
				'previousFocusedElement': $('#open-trade-in-estimator-overlay')
			});
		};

		$calcCtrl.setApplyForFinancingParameters = () => {
			let otherFees = $calcCtrl.freight + $calcCtrl.acTax + $calcCtrl.greenLevy;
			let incentivesTotal = $calcCtrl.discountBeforeTaxTotal + $calcCtrl.discountAfterTaxTotal;
			let basePriceAndOptions = Number($calcCtrl.pricing.base.msrp) + Number($calcCtrl.option || 0);
			let pricing = $calcCtrl.pricing;
			let applyForFinancingParameters = {
				'acode': $calcCtrl.acode,
				'trimName': $calcCtrl.trimName,
				'modelYearId': $calcCtrl.modelYearId,
				'year': $calcCtrl.year,
				'nameplateCode': $calcCtrl.nameplateCode,
				'brandCode': $calcCtrl.brandCode,
				'provinceCode': $calcCtrl.provinceCode,
				'packageAndOptionsCode': $calcCtrl.packageAndOptionsCode,
				'otherFees': otherFees,
				'basePriceAndOptions': basePriceAndOptions,
				'pricing': pricing,
				'financeDiscountTotalBeforeTax': $calcCtrl.financeDiscountTotalBeforeTax,
				'financeDiscountTotalAfterTax': $calcCtrl.financeDiscountTotalAfterTax,
				'incentivesTotal': incentivesTotal,
				'financeTerm': Number($calcCtrl.financeInterestRate[$calcCtrl.financeTerm].key),
				'financePayment': Number($calcCtrl.paymentFinance.payment),
				'financeFrequency': $calcCtrl.paymentFrequency,
				'downpayment': $calcCtrl.downPayment,
				'tradeValue': $calcCtrl.tradeValue,
				'oweOnTrade': $calcCtrl.tradeOwed,
				'scratchSave': $calcCtrl.scratchSave,
				'jellyUrl': $calcCtrl.jellyUrl
			};

			if ($calcCtrl.sniApplyForFinanceCta === 'true') {
				applyForFinancingParameters = {
					'acode': $calcCtrl.acode,
					'modelYearId': $calcCtrl.modelYearId,
					'provinceCode': $calcCtrl.provinceCode,
					'packageAndOptionsCode': $calcCtrl.packageAndOptionsCode,
					'dealerPrice': $calcCtrl.dealerPrice,
					'pricing': pricing,
					'financeTerm': Number(
							$calcCtrl.financeInterestRate[$calcCtrl.financeTerm].key),
					'downpayment': $calcCtrl.downPayment,
					'tradeValue': $calcCtrl.tradeValue,
					'oweOnTrade': $calcCtrl.tradeOwed,
					'vin': $calcCtrl.vin
				};
			}

			window.FcaCookieChecker.addSessionStorage('applyForFinancingParameters',
					JSON.stringify(applyForFinancingParameters));

			let href = $calcCtrl.brandSite + "/";

			if ($calcCtrl.language === 'en') {
				href = href + $calcCtrl.language + "/apply-for-financing";
			} else {
				href = href + $calcCtrl.language + "/demande-de-financement";
			}

			if ($calcCtrl.vin) {
				href = href + "#vin=" + $calcCtrl.vin;
			}
			$window.location.href = href;
		};

		$calcCtrl.setContactThisDealerParameters = () => {
			let contactThisDealerParameter = {
				'acode': $calcCtrl.acode,
				'trimName': $calcCtrl.trimName,
				'modelYearId': $calcCtrl.modelYearId,
				'nameplateCode': $calcCtrl.nameplateCode,
				'brandCode': $calcCtrl.brandCode,
				'provinceCode': $calcCtrl.provinceCode,
				'year': $calcCtrl.year,
				'scratchSave': $calcCtrl.scratchSave
			};

			if ($calcCtrl.sniApplyForFinanceCta === 'true') {
				contactThisDealerParameter = {
					'vin': $calcCtrl.vin
				}
			}

			window.FcaCookieChecker.addSessionStorage('contactThisDealerParameter', JSON.stringify(contactThisDealerParameter));
		};

		/*
		 * This does the exact same thing as rate.filter.js,
		 * but with an initial check to see if rate is undefined.
		 * Decided to make this specific function to avoid
		 * causing a massive regression barely a week before launch.
		 * And this issue seems to only happen in Apply for financing.
		 * fix for https://issues.nurun.com/browse/MFBR-3037
		 * */
		$calcCtrl.formatFinanceInterestRate = rate => {
			if(rate !== undefined) {
				if (typeof(rate) === 'number') {
					rate = rate.toString();
				}

				rate = rate.match(/^-?\d+(?:\.\d{0,2})?/)[0];

				if ($calcCtrl.language === "fr") {
					rate = rate.replace('.', ',');
				}
			}
			return rate;
		};

		$calcCtrl.closeOverlay = () => {
			if ($calcCtrl.modalDialog) {
				$calcCtrl.modalDialog[0].removeEventListener('keydown', $calcCtrl.focusTrap);
			}

			$rootScope.$broadcast('new-inventory:close-calculator', {});
		};

		// is empty function for null check
		$calcCtrl.isEmpty = function(value) {
			return (value == "" || value == null);
		};

		$calcCtrl.broadCastPaymentChange = paymentType => {
			$rootScope.$broadcast('navigation: payment-type-changed', {
				'type': paymentType,
			});
		};

		/**
		 * TODO: separate the parameter between payment type and best-rate toggle
		 *
		 * AltLease and altFinance are always best rate (the drop-down actually shows "Lease best rate" before sending altLease)
		 */
		$calcCtrl.changeActiveTab = newTab => {

			let newTabValue = $calcCtrl.activeTab;
			let newBestRateValue = $calcCtrl.useBestRate;
			switch (newTab) {
				case 'altFinance':
					newTabValue = 'finance';
					newBestRateValue = true;
					break;
				case 'finance':
					newTabValue = 'finance';
					newBestRateValue = false;
					break;
				case 'cash':
					newTabValue = 'cash';
					break;
				case 'lease':
					newTabValue = 'lease';
					newBestRateValue = false;
					break;
			}
			// this is an actual modification
			if (newTabValue != $calcCtrl.activeTab || newBestRateValue != $calcCtrl.useBestRate) {
				$calcCtrl.activeTab = newTabValue;
				$calcCtrl.useBestRate = newBestRateValue;
				// show the payment frequency on finance and lease
				$calcCtrl.showPaymentFrequency = newTabValue === 'finance' || newTabValue === 'lease';

				if (!$calcCtrl.isApplyForFinancingMode) {
					$calcCtrl.assignPricingData();
					$calcCtrl.calcUpdatedValues();
				}
			}
		};

		/**
		 * Only used in apply for financing mode
		 */
		$calcCtrl.setModelYearProgramsLegacy = (provinceCode) => {
			$calcCtrl.currentModelYearId = $calcCtrl.modelYearId;
			calculatorService.getProgramsLegacy($calcCtrl.modelYearId, provinceCode).then(value => {
					$calcCtrl.programs = value.data;
					$calcCtrl.updateCalculatorParametersLegacy();
					$calcCtrl.calcUpdatedValues();
				},
				value => {
					console.error('api call for program return 404, return value:', value);
					$calcCtrl.programs = [];
					$calcCtrl.updateCalculatorParametersLegacy();
					$calcCtrl.calcUpdatedValues();
				}).catch(function(e) {
			});
		};

		$calcCtrl.updateCalculatorParametersLegacy = () => {
			$calcCtrl.discountBeforeTax = $calcCtrl.getIncentivesLegacy($calcCtrl.incentives, false);
			$calcCtrl.discountAfterTax = $calcCtrl.getIncentivesLegacy($calcCtrl.incentives, true);
			$calcCtrl.discountBeforeTaxTotal = $calcCtrl.sumValueInArrayOfObjectsLegacy($calcCtrl.discountBeforeTax, 'cash');
			$calcCtrl.discountAfterTaxTotal = $calcCtrl.sumValueInArrayOfObjectsLegacy($calcCtrl.discountAfterTax, 'cash');

			// legacy doesn't have finance/lease discounts, all discounts are shared
			$calcCtrl.financeDiscountTotalBeforeTax = $calcCtrl.discountBeforeTaxTotal;
			$calcCtrl.financeDiscountTotalAfterTax = $calcCtrl.discountAfterTaxTotal;
			$calcCtrl.leaseDiscountTotalBeforeTax = $calcCtrl.discountBeforeTaxTotal;
			$calcCtrl.leaseDiscountTotalAfterTax = $calcCtrl.discountAfterTaxTotal;

			// applyForFinancingIncentivesTotal is overriding discount values
			if ($calcCtrl.applyForFinancingIncentivesTotal > 0) {
				$calcCtrl.discountAfterTaxTotal = 0;
				$calcCtrl.discountBeforeTaxTotal = $calcCtrl.applyForFinancingIncentivesTotal;
			}

			let maxFinanceAdjustment = $calcCtrl.basePrice
				+ $calcCtrl.freight
				+ $calcCtrl.acTax
				+ $calcCtrl.greenLevy
				+ ($calcCtrl.luxuryTax || 0)
				+ $calcCtrl.options
				- $calcCtrl.discountBeforeTaxTotal
				- $calcCtrl.discountAfterTaxTotal;

			if ($calcCtrl.dealerPrice > 0) {
				maxFinanceAdjustment = $calcCtrl.dealerPrice;
			}

			if ($calcCtrl.adminFee > 0) {
				maxFinanceAdjustment += $calcCtrl.adminFee;
			}

			$calcCtrl.maxFinanceAdjustment = maxFinanceAdjustment;

			let programJson = $calcCtrl.selectProgramLegacy($calcCtrl.programs, $calcCtrl.acode);

			$calcCtrl.calculatorData = calculatorService.buildCalculatorData(programJson, $calcCtrl.packageAndOptionsCode, $calcCtrl.calculatorDefaultFinanceRate);

			if ($calcCtrl.calculatorData.financeInterestRate) {
				$calcCtrl.financeInterestRate = $calcCtrl.convertObjectToArrayLegacy($calcCtrl.calculatorData.financeInterestRate);
			} else {
				$calcCtrl.financeInterestRate = [];
			}

			if ($calcCtrl.calculatorData.leaseInterestRate) {
				$calcCtrl.leaseInterestRate = $calcCtrl.convertObjectToArrayLegacy($calcCtrl.calculatorData.leaseInterestRate);
			} else {
				$calcCtrl.leaseInterestRate = [];
			}

			if ($calcCtrl.calculatorData.residualList) {
				$calcCtrl.residualList = $calcCtrl.convertObjectToArrayLegacy($calcCtrl.calculatorData.residualList);
			} else {
				$calcCtrl.residualList = [];
			}
			// We take the maximum value in the array block
			$calcCtrl.financeTerm = $calcCtrl.financeInterestRate.length - 1;
			$calcCtrl.leaseTerm = $calcCtrl.leaseInterestRate.length - 1;
			$calcCtrl.annualMileageChoice = $calcCtrl.setDefaultMileageLegacy();

			$scope.$$postDigest(function () {
				if ($calcCtrl.financeTermOverride != null) {
					for (let i = 0; $calcCtrl.financeInterestRate.length > i; i++) {
						if ($calcCtrl.financeInterestRate[i].key === $calcCtrl.financeTermOverride) {
							$calcCtrl.financeTerm = i;
						}
					}
				}
			})
		};

		$calcCtrl.selectProgramLegacy = (programs, acode) => {
			let filteredProgram = [];
			let program = {};
			if (programs.length > 0) {
				filteredProgram = programs.filter(program => program.acode === acode);

				if (filteredProgram.length > 0) {
					program = filteredProgram[0];
				}
			}
			return program;
		};

		// Call Calculator service
		$calcCtrl.calcUpdatedValuesLegacy = () => {
			$calcCtrl.calculatePaymentsLegacy();
		};

		/**
		 * Takes an object, returns a sorted array of objects containing key and value pairs
		 * @param data
		 * @returns {any[]}
		 */
		$calcCtrl.convertObjectToArrayLegacy = (data = {}) => {
			let arrayResult = Object.keys(data).map(key => {
				return { key, value: data[key] }
			});

			arrayResult = this.sortKeys(arrayResult);

			return arrayResult;
		};


		/*
		 Filter incentive after and before tax
		 */
		$calcCtrl.getIncentivesLegacy = (incentives, isAfterTaxes = false) => {
			var result = [];
			if(incentives) {
				// isAfterTaxes is a boolean to specify whether we have the incentives before or after taxes
				result = incentives.filter(incentive => incentive.afterTaxes === isAfterTaxes && incentive.cash);
			}

			return result;
		};

		// TODO: move to util
		// Sum one of the numerical values in each object in an array of objects
		$calcCtrl.sumValueInArrayOfObjectsLegacy = (objects, dataKey) => {
			return objects.reduce((total, object) => {
				return total + parseFloat(object[dataKey])
			}, 0);
		};

		$calcCtrl.calculatePaymentsLegacy = () => {
			let baseAmount = $calcCtrl.calculateBaseAmount();

			// first thing we need to do is calculate net amounts
			$calcCtrl.cashNetAmount = $calcCtrl.calculateCashNetAmount(!!$calcCtrl.includeSalesTax);
			$calcCtrl.financeNetAmount = $calcCtrl.calculateFinanceNetAmount(!!$calcCtrl.includeSalesTax);
			$calcCtrl.leaseNetAmount = $calcCtrl.calculateLeaseNetAmount(!!$calcCtrl.includeSalesTax);

			let financeAmount = baseAmount;
			if ($calcCtrl.adminFee) {
				// admin fees are added dealer charge
				financeAmount += $calcCtrl.adminFee;
			}

			let discountBeforeTaxTotal = $calcCtrl.discountBeforeTaxTotal;
			let discountAfterTaxTotal = $calcCtrl.discountAfterTaxTotal;
			if ($calcCtrl.dealerPrice > 0) {
				// if there's a dealer price, the discounts are not added
				financeAmount = $calcCtrl.dealerPrice;
				discountBeforeTaxTotal = 0;
				discountAfterTaxTotal = 0;
			}

			let financeInterestRate = null;
			let financeTerm = null;
			$calcCtrl.paymentFinanceWithTax = null;
			$calcCtrl.paymentFinance = null;
			if ($calcCtrl.financeInterestRate.length > 0) {
				financeInterestRate = $calcCtrl.financeInterestRate[$calcCtrl.financeTerm].value;
				financeTerm = $calcCtrl.financeInterestRate[$calcCtrl.financeTerm].key;
				let financeData = {
					'amount': financeAmount,
					'interestRate': financeInterestRate,
					'term': financeTerm,
					'downpayment': $calcCtrl.downPayment,
					'tradeInValue': $calcCtrl.tradeValue,
					'oweOnTrade': $calcCtrl.tradeOwed,
					'nbOfPaymentPerYear': $calcCtrl.paymentFrequencies[$calcCtrl.paymentFrequency].payments,
					'discountBeforeTax': discountBeforeTaxTotal,
					'discountAfterTax': discountAfterTaxTotal
				};

				$calcCtrl.paymentFinance = calculatorService.calculateFinancePayment(financeData);
				$calcCtrl.paymentFinanceWithTax = calculatorService.calculateFinancePaymentWithTax(financeData, $calcCtrl.salesTax, $calcCtrl.provinceCode, $calcCtrl.language);
			}

			let leaseAmount = baseAmount;
			if ($calcCtrl.dealerPrice > 0) {
				// For lease when we have a dealer price it includes all discounts.
				leaseAmount = $calcCtrl.dealerPrice;
				console.debug('We have a dealerPrice setting both discount to 0');
				discountBeforeTaxTotal = 0;
				discountAfterTaxTotal = 0;
			} else if ($calcCtrl.adminFee) {
				// if there's an admin fee, subtract it to discounts before tax
				discountBeforeTaxTotal-=$calcCtrl.adminFee;
			}

			let leaseInterestRate = null;
			let leaseTerm = null;
			let leaseInterestResidual = null;
			$calcCtrl.paymentLease = null;
			$calcCtrl.paymentLeaseWithTax = null;
			if ($calcCtrl.leaseInterestRate.length > 0
				&& $calcCtrl.residualList.length > 0) {
				leaseInterestRate = $calcCtrl.leaseInterestRate[$calcCtrl.leaseTerm].value;
				leaseTerm = $calcCtrl.leaseInterestRate[$calcCtrl.leaseTerm].key;
				leaseInterestResidual = $calcCtrl.residualList[$calcCtrl.annualMileageChoice].value[$calcCtrl.leaseInterestRate[$calcCtrl.leaseTerm].key];

				let leaseData = {
					'amount': leaseAmount,
					'gkrp': $calcCtrl.gkrp,
					'interestRate': leaseInterestRate,
					'term': leaseTerm,
					'residual': leaseInterestResidual,
					'downpayment': $calcCtrl.downPayment,
					'tradeInValue': $calcCtrl.tradeValue,
					'oweOnTrade': $calcCtrl.tradeOwed,
					'nbOfPaymentPerYear': $calcCtrl.paymentFrequencies[$calcCtrl.paymentFrequency].payments,
					'discountBeforeTax': discountBeforeTaxTotal,
					'discountAfterTax': discountAfterTaxTotal
				};

				$calcCtrl.paymentLease = calculatorService.calculateLeasePayment(leaseData);
				$calcCtrl.paymentLeaseWithTax = calculatorService.calculateLeasePaymentWithTax(leaseData, $calcCtrl.salesTax, $calcCtrl.provinceCode, $calcCtrl.language);
			}

			$rootScope.$broadcast('calculator:finance-rate-changed', {
				financeInterestRate: financeInterestRate,
				monthlyEstimate: $calcCtrl.paymentFinance != null ? $calcCtrl.paymentFinance.payment : null
			});

			$rootScope.$broadcast('calculator:update-payment', {
				'netAmount': $calcCtrl.calculateFinanceNetAmount(false),
				'financePayment': $calcCtrl.paymentFinance != null ? $calcCtrl.paymentFinance.payment : null,
				'financeRate': financeInterestRate,
				'financeTerm': financeTerm,
				'frequency': $calcCtrl.paymentFrequencies[$calcCtrl.paymentFrequency].key,
				'leasePayment': $calcCtrl.paymentLease != null ? $calcCtrl.paymentLease.payment : null,
				'leaseTerm': leaseTerm,
				'leaseInterestRate': leaseInterestRate,
				'leaseResidualValue': $calcCtrl.paymentLease != null ? $calcCtrl.paymentLease.residual : null,
				'leaseMileage': $calcCtrl.leasePrograms != null ? $calcCtrl.leasePrograms[$calcCtrl.annualMileageChoice].km : null,
				'financeMode': $calcCtrl.activeTab,
				'totalDiscount': $calcCtrl.sumIncentives(),
				'downPayment': $calcCtrl.downPayment,
				'tradeInValue': $calcCtrl.tradeValue,
				'tradeOwed': $calcCtrl.tradeOwed
			});

			// Forces update to the model so that it doesn't set the value to '50', the browser's default
			// https://issues.nurun.com/projects/MFBR/issues/MFBR-2589

			if($calcCtrl.vin === "" && $calcCtrl.scratchSave === "") {
				/*$scope.$watch('$calcCtrl.downPayment', (newValue, oldValue) => {
					$calcCtrl.downPaymentCpt++;
					if($calcCtrl.downPaymentCpt <= 1) {
						$calcCtrl.downPayment = 0;
					}
				});*/
				$scope.$watch('$calcCtrl.tradeValue', (newValue, oldValue) => {
					$calcCtrl.tradeValueCpt++;
					if($calcCtrl.tradeValueCpt <= 1) {
						$calcCtrl.tradeValue = 0;
					}
				});
			}

			if(!$calcCtrl.isApplyForFinancingMode || ($calcCtrl.vin === "" && $calcCtrl.scratchSave === "")) {
				$scope.$watch('$calcCtrl.tradeOwed', (newValue, oldValue) => {
					if(!$calcCtrl.tradeOwedTriggered) {
						$calcCtrl.tradeOwed = 0;
					}
				});
			}


			initEmptyField($calcCtrl);
		};


		$calcCtrl.calculateLeasePaymentsPerTerm = () => {
			let baseAmount = $calcCtrl.calculateBaseAmount();

			let leaseInterestRate = null;
			let leaseTerm = null;
			let leaseMileage = null;
			let leaseNet = null;
			let leaseDiscounts = null;
			$calcCtrl.paymentLease = null;
			$calcCtrl.paymentLeaseWithTax = null;

			if ($calcCtrl.leasePrograms
					&& $calcCtrl.leaseInterestRate.length > 0
					&& $calcCtrl.leaseInterestRate[$calcCtrl.leaseTerm]) {

				// Calculate lease
				let leaseAmount = baseAmount;

				$calcCtrl.leaseDiscountTotalBeforeTax = $calcCtrl.sumCashIncentives($calcCtrl.leaseIncentives, false);
				$calcCtrl.leaseDiscountTotalAfterTax = $calcCtrl.sumCashIncentives($calcCtrl.leaseIncentives, true);

				let leaseDiscountBeforeTaxTotal = $calcCtrl.leaseDiscountTotalBeforeTax;
				let leaseDiscountAfterTaxTotal = $calcCtrl.leaseDiscountTotalAfterTax;

				if ($calcCtrl.dealerPrice > 0) {
					// For lease when we have a dealer price it includes all discounts.
					leaseAmount = $calcCtrl.dealerPrice;
					leaseDiscountBeforeTaxTotal = 0;
					leaseDiscountAfterTaxTotal = 0;
				} else if (!$calcCtrl.vin && $calcCtrl.adminFee) {
					// admin fees are already calculated in sni mode
					// if there's an admin fee, subtract it to discounts before tax
					leaseDiscountBeforeTaxTotal -= $calcCtrl.adminFee;
				}

				// subtract the dealer delta from the discounts (if the delta is negative, this will increase the discount)
				leaseDiscountBeforeTaxTotal -= ($calcCtrl.cashDealerDelta || 0);

				leaseInterestRate = $calcCtrl.leaseInterestRate[$calcCtrl.leaseTerm].value;
				leaseTerm = $calcCtrl.leaseInterestRate[$calcCtrl.leaseTerm].key;

				// keep the lease discount and net amount
				leaseDiscounts = leaseDiscountBeforeTaxTotal + leaseDiscountAfterTaxTotal;
				// keep the lease net amount (does not include down-payment or trade-in)
				leaseNet = leaseAmount - leaseDiscounts;

				// recover current program rate
				let leaseTermObject = $calcCtrl.leasePrograms[$calcCtrl.annualMileageChoice].terms.filter(term => Number(term.duration) == Number(leaseTerm));

				if (leaseTermObject.length > 0) {
					leaseMileage = $calcCtrl.leasePrograms[$calcCtrl.annualMileageChoice].km;
					let leaseData = {
						'debug' : 'payment',
						'amount': leaseAmount,
						'gkrp': $calcCtrl.gkrp,
						'interestRate': leaseTermObject[0].rate,
						'term': leaseTermObject[0].duration,
						'residual': leaseTermObject[0].residual,
						'downpayment': $calcCtrl.downPayment,
						'tradeInValue': $calcCtrl.tradeValue,
						'oweOnTrade': $calcCtrl.tradeOwed,
						'nbOfPaymentPerYear': $calcCtrl.paymentFrequencies[$calcCtrl.paymentFrequency].payments,
						'discountBeforeTax': leaseDiscountBeforeTaxTotal,
						'discountAfterTax': leaseDiscountAfterTaxTotal
					};

					$calcCtrl.paymentLease = calculatorService.calculateLeasePayment(leaseData);
					$calcCtrl.paymentLeaseWithTax = calculatorService.calculateLeasePaymentWithTax(leaseData, $calcCtrl.salesTax, $calcCtrl.provinceCode, $calcCtrl.language);

					$calcCtrl.leaseNetAmount = $calcCtrl.calculateLeaseNetAmount(!!$calcCtrl.includeSalesTax);
				}
			}

			return $calcCtrl.paymentLease.payment;
		};

		$calcCtrl.isDaa = () => {
			return $calcCtrl.siteGroupCode === 'daa';
		};

		$calcCtrl.getDisclaimer = (key) => {
			let result = null;
			if ($calcCtrl.disclaimers) {
				result = $calcCtrl.disclaimers[key];
				if (!result) {
					console.error(`calculator has no disclaimer for ${key}`);
				}
			} else {
				console.error("calculator has no disclaimers");
			}
			return result;
		};

		$rootScope.$on('calculator:switch-calculator-state', (evt, args) => {
			$calcCtrl.switchCalculatorState(args[0]);
		});

		/*
		* Change calculator state to active/unactive according to parameter received
		* @param {bool} activeCalculator
		*/
		$calcCtrl.switchCalculatorState = activeCalculator => {
			const inputList = $('.C_CAL_TAB-input-list');

			if (activeCalculator) {
				$calcCtrl.showPaymentSummary = true;
				inputList.hasClass('C_CAL-disabled') ? inputList.removeClass('C_CAL-disabled') : '';
			} else {
				$calcCtrl.showPaymentSummary = false;
				inputList.hasClass('C_CAL-disabled') ? '' : inputList.addClass('C_CAL-disabled');
			}
		};

		/**
		 * Set value of trade-in value.
		 *
		 * Useful when the trade-in estimator has been used to determine a trade-in value.
		 *
		 * @param event
		 * @param data
		 */
		$calcCtrl.applyTradeInValue = (event, data) => {
			if (data && data.tradeInValue) {
				$calcCtrl.tradeValue = data.tradeInValue;
				$calcCtrl.animateScrollOnBtnClick('#zones_summary_blocks_calculator');
			}
		};

		/*
		 * Take current finance term from slider value
		 * If unavailable, take the maximum value in the array
		 */
		let getCurrentFinanceTermFromSlider = (needTermInit) => {
			// default value
			let result = $calcCtrl.financeInterestRate.length - 1;
			if(!needTermInit) {
				if ($calcCtrl.currentRates && $calcCtrl.currentRates.finance) {
					let financeTermIndex = $calcCtrl.financeInterestRate.map(function (e) {
						return e.key;
					}).indexOf($calcCtrl.currentRates.finance.duration.toString());

					if (financeTermIndex >= 0) {
						result = financeTermIndex;
					}
				}
			}
			return result;
		};

		/*
		 * Take current lease term from slider value
		 * If unavailable, take the maximum value in the array
		 */
		let getCurrentLeaseTermFromSlider = (needTermInit) => {
			// default value
			let result = $calcCtrl.leaseInterestRate.length - 1;
			if(!needTermInit) {
				if($calcCtrl.currentRates && $calcCtrl.currentRates.lease) {
					let leaseTermIndex = $calcCtrl.leaseInterestRate.map(function(e) {
						return e.key;
					}).indexOf($calcCtrl.currentRates.lease.duration.toString());

					if(leaseTermIndex >= 0) {
						result = leaseTermIndex;
					}
				}
			}
			return result;
		};

		/**
		 * Calculate the cash of the incentive depending on the sales taxes inclusion status
		 *
		 * @param incentive
		 */
		$calcCtrl.calculateCashIncentive = (incentive) => {
			let cash = incentive.cash;
			if (!incentive.beforeTax && !$calcCtrl.includeSalesTax) {
				cash = incentive.cash * 1.0;
			}

			if (!$calcCtrl.vin && !incentive.cashTaxFree && !$calcCtrl.includeSalesTax) {
				cash = cash / $calcCtrl.currentTaxRate;
			}

			return cash;
		};
	}
})();
