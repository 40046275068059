/* eslint-disable indent */
(function () {
    try {
	let $vehicleSelector = $('.js-C_VS');

	let hashParameters = {};

	let $switchAllBrand = $vehicleSelector.find('.js-C_VS-switchAllBrand');
	let $nameplateHolder = $vehicleSelector.find('.js-C_VS-nameplateHolder');

	let $tabbarBtn = $vehicleSelector.find('[data-tabbarbtn]');
	let $tabBarAllBtn = $vehicleSelector.find('.js-C_VS-tabBarAllBtn');

	let $nameplates = $vehicleSelector.find('.js-commonNameplate');
	let $brands = $vehicleSelector.find('.js-C_VS-brandHolder');

	let $recommenderSlider = $vehicleSelector.find('.js-recommenderIconSlider');

	let currentType = 'all';
	let showAllBrands = false;

	// ---------------------------------------------
	// ---------------------------------------------
	// INITIALIZE VEHICLE SELECTOR
	// ---------------------------------------------
	// ---------------------------------------------

	function init() {
		if ($vehicleSelector.length > 0) {
			applyHashParameters();
			initShowAllBrandsSwitch();
			filterTabbarBtn();
			$tabBarAllBtn.addClass('active');
			$tabBarAllBtn.attr('aria-selected', 'true');
			initFilterTypeBtn();
			initRecommenderSlider();
			showOnlyDealerBrands();
			storeAffiliateId();
			cleanCustomOrderParam();

			const affiliateEncryptedParam = getAffiliateParam('encpar');
			const affiliateEaProgram = getAffiliateParam('eaProgram');

			if (affiliateEncryptedParam) {
				window.sessionStorage.setItem('affiliateEncryptedParam', affiliateEncryptedParam)
			}
			if (affiliateEaProgram) {
				window.sessionStorage.setItem('affiliateEaProgram', affiliateEaProgram)
			}
		}

		document.addEventListener('keypress', (e) => {
			if ( e.code === 'Enter' && $(":focus").hasClass('C_VS-filterButton') ) {
				$(".C_VS-filterInput").click();
			}
		});
	}
	function initRecommenderSlider() {
		const pauseButton = $vehicleSelector.find('.C_VS-recommenderIconBtn--pause');
		const playButton = $vehicleSelector.find('.C_VS-recommenderIconBtn--play');

		if (pauseButton && playButton) {
			pauseButton.click(() => {
				$recommenderSlider.addClass('C_VS-recommenderIcon--paused');
				pauseButton.addClass('C_VS-recommenderIconBtn--hidden');
				playButton.removeClass('C_VS-recommenderIconBtn--hidden');
			});

			playButton.click(() => {
				$recommenderSlider.removeClass('C_VS-recommenderIcon--paused');
				pauseButton.removeClass('C_VS-recommenderIconBtn--hidden');
				playButton.addClass('C_VS-recommenderIconBtn--hidden');
			});
		}
	}

	function initShowAllBrandsSwitch() {
		showAllBrands = $switchAllBrand.prop('checked');
		if (showAllBrands === true) {
			$nameplateHolder.addClass('-showAllBrands');
		}
		$switchAllBrand.on('change', function () {
			showAllBrands = $switchAllBrand.prop('checked');
			$nameplateHolder.toggleClass('-showAllBrands');
			// if the current selected type as no vehicles, reverd to all vehicle type
			if (currentType !== 'all' && $nameplates.filter('[data-vehicle-type="' + currentType + '"]:visible').length === 0) {
				$tabBarAllBtn.trigger('click');
			}
			if (hashParameters.dealerId && hashParameters.dealerBrands) {
				showOnlyDealerBrands();
			}
			filterTabbarBtn();

			window.PubSubManager.publish("EVENT.toggle-view-all-brands", {
				showAllBrands: showAllBrands
			});
		});
	}

	function filterTabbarBtn() {
		let nbrCars;
		let nbrSuvs;
		let nbrTrucks;
		let nbrMiniVans;
		let nbrVans;
    let brandCode = window.FCA_SITES_CONFIG.name;

		if (showAllBrands === true) {
			nbrCars = $nameplates.filter('[data-vehicle-type="cars"]').length;
			nbrSuvs = $nameplates.filter('[data-vehicle-type="suvs"]').length;
			nbrTrucks = $nameplates.filter('[data-vehicle-type="trucks"]').length;
			nbrMiniVans = $nameplates.filter('[data-vehicle-type="mini-vans"]').length;
			nbrVans = $nameplates.filter('[data-vehicle-type="vans"]').length;
		} else {
			nbrCars = $brands.filter(':visible').find('.js-commonNameplate[data-vehicle-type="cars"]').length;
			nbrSuvs = $brands.filter(':visible').find('.js-commonNameplate[data-vehicle-type="suvs"]').length;
			nbrTrucks = $brands.filter(':visible').find('.js-commonNameplate[data-vehicle-type="trucks"]').length;
			nbrMiniVans = $brands.filter(':visible').find('.js-commonNameplate[data-vehicle-type="mini-vans"]').length;
			nbrVans = $brands.filter(':visible').find('.js-commonNameplate[data-vehicle-type="vans"]').length;
		}

		nbrCars === 0 ? $('[data-tabbarbtn="cars"]').hide() : $('[data-tabbarbtn="cars"]').show();
		nbrSuvs === 0 ? $('[data-tabbarbtn="suvs"]').hide() : $('[data-tabbarbtn="suvs"]').show();
		nbrTrucks === 0 ? $('[data-tabbarbtn="trucks"]').hide() : $('[data-tabbarbtn="trucks"]').show();
		nbrMiniVans === 0 ? $('[data-tabbarbtn="mini-vans"]').hide() : $('[data-tabbarbtn="mini-vans"]').show();
		nbrVans === 0 ? $('[data-tabbarbtn="vans"]').hide() : $('[data-tabbarbtn="vans"]').show();

		$('.js-C_VS-filterHolder').addClass('active');
		$('.js-C_VS-nameplateHolder').addClass('with-margin-top');
		if($('[data-tabbarbtn]:visible').length >= 3 ) {
			$('.js-C_VS-filterHolder').addClass('active');
			$('.js-C_VS-nameplateHolder').addClass('with-margin-top');
			$('.js-affiliate-logo').addClass('with-margin-top');
		} else {
			$('.js-C_VS-filterHolder').removeClass('active');
			$('.js-C_VS-nameplateHolder').removeClass('with-margin-top');
		}

	}

	function initFilterTypeBtn() {
		$tabbarBtn.on('click', function () {
			/* eslint-disable no-invalid-this */
			$tabbarBtn.removeClass('active');
			$tabbarBtn.attr('aria-selected', 'false');
			$(this).addClass('active');
			$(this).attr('aria-selected', 'true');
			currentType = $(this).data('tabbarbtn');
			filterNameplates();
			hideEmptyBrands();
		});
	}

	function showOnlyDealerBrands() {
		if (!hashParameters || !hashParameters.dealerBrands) {
			return;
		}
		$brands.removeClass('js-hideEmptyBrand');
		$brands.each(function () {
			$(this).addClass(hashParameters.dealerBrands.split('|').some(brandCode => {
				if (brandCode == 'ram') {
					return this.classList.contains('ramtruck');
				} else {
					return this.classList.contains(brandCode);
				}

			}) ? '' : 'js-hideEmptyBrand');
		});
	}

	function filterNameplates() {
		$nameplates.removeClass('js-hideNameplate');
		if (currentType !== 'all') {
			$nameplates.not('[data-vehicle-type="' + currentType + '"]').addClass('js-hideNameplate');
		}
	}

	function hideEmptyBrands() {
		$brands.removeClass('js-hideEmptyBrand');
		$brands.each(function () {
			let numberNameplateVisible = $(this).find('.js-commonNameplate').not('.js-hideNameplate').length;
			if (numberNameplateVisible === 0) {
				$(this).addClass('js-hideEmptyBrand');
			}
		});
		showOnlyDealerBrands();
	}

	function applyHashParameters() {
		hashParameters = getHashParameters();
		if (hashParameters && hashParameters.viewAllBrands === 'true') {
			$switchAllBrand.prop('checked', true);
		}
	}

	init();
    } catch(err) {
        console.error("error in vehicle selector", err);
    }
})();
