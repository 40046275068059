(function () {
	angular
		.module('fca.brandApp')
		.service('brandBp', BrandBuildAndPrice);

	function BrandBuildAndPrice($http) {
		const $service = this;
		////////////////
		/// API methods
		////////////////

		/**
		 * Generic call method, with success/error handlers
		 * @param {String} URL
		 * @param {Function} onSuccess - Success callback handler, should accept an object parameter
		 * @param {Function} onError - Error callback handler, should accept an object parameter - Optional
		 */
		$service.get = (URL, onSuccess, onError = null) => {
			$http.get(URL).then(response => {
				onSuccess(response.data);
			}, reason => {
				if (onError) {
					onError(reason);
				} else {
					console.error(reason);
				}
			});
		}

		/**
		 * Get method with callback added for trimGroupSelector
		 */
		$service.getWithCallback = (URL, onSuccess, year, modelYearId, onError, skipCache = false) => {
			if (!skipCache) {
				const cachedResponse = JSON.parse(sessionStorage.getItem(URL))
				if (validateCachedResponse(cachedResponse)) {
					onSuccess(cachedResponse.data, year, modelYearId);
					return;
				}
			}

			$http.get(URL).then(response => {
				response.timeStamp = Date.now();
				window.FcaCookieChecker.addSessionStorage(URL, JSON.stringify(response));
				onSuccess(response.data, year, modelYearId);
			}, reason => {
				if (onError) {
					onError(reason);
				} else {
					console.error(reason);
				}
			});
		}

		/**
		 *
		 * @param {String} URL
		 * @param {Object} data - The data object being sent with the request
		 * @param {Function} onSuccess - Success callback handler, should accept an object parameter
		 * @param {Function} onError - Error callback handler, should accept an object parameter - Optional
		 */
		$service.post = (URL, data, onSuccess, onError = null) => {
			$http.post(URL, data).then(response => {
				onSuccess(response);
			}, reason => {
				if (onError) {
					onError(reason);
				} else {
					console.error(reason);
				}
			});
		}

		/**
		 * Method used to add/remove an option from the current vehicle configuration.
		 * @param {Object} data
		 *   - {String} acode
		 *   - {String} language
		 *   - {String} modelYearId
		 *   - {String} nameplateCode
		 *   - {String} year
		 *   - {String} brandCode
		 *   - {String} provinceCode
		 *   - {String} packageCode
		 *   - {String} optionCode
		 *   - {String} scratchSave
		 *   - {String} language
		 * @param {Function} onSuccess - Success callback handler, should accept an object parameter
		 * @param {Function} onError - Error callback handler, should accept an object parameter - Optional
		 */
		$service.selectOption = (data, onSuccess, onError = null) => {
			let apiUrl = '/api/buildandprice/select-option';

			let postData = {
				acode: data.acode,
				language: data.language,
				modelYearId: data.modelYearId,
				nameplateCode: data.nameplateCode,
				year: data.year,
				brand: data.brandCode,
				provinceCode: data.provinceCode,
				packageCode: data.packageCode,
				optionCode: data.optionCode,
				scratchSave: data.scratchSave
			};
			$service.post(apiUrl, postData, onSuccess, onError);
		}

		/**
		 * Method used to get the swatches for a vehicle
		 * @param {Object} requestParameters
		 *   - {String} brand
		 *   - {String} nameplate
		 *   - {String} year
		 *   - {String} modelYearId
		 * @param {Function} onSuccess - Success callback handler, should accept an object parameter
		 * @param {Function} onError - Error callback handler, should accept an object parameter - Optional
		 */
		$service.getSwatches = (requestParameters, onSuccess, onError = null) => {
			const apiUrl = `/data/buildandprice/en/` +
				`brand/${requestParameters.brand}/` +
				`nameplate/${requestParameters.nameplate}/` +
				`year/${requestParameters.year}/` +
				`modelyear/${requestParameters.modelYearId}/swatches`;

			$service.get(apiUrl, onSuccess, onError);
		}

		/**
		 * Method used to get the sales tax for all provinces
		 * @param requestParameters
		 *  - {String} modelYearId
		 * @param {Function} onSuccess - Success callback handler, should accept an object parameter
		 * @param {Function} onError - Error callback handler, should accept an object parameter - Optional
		 */
		$service.getTaxes = (requestParameters, onSucess, onError) => {
			const apiUrl = `/data/config/salestax?modelYearId=${requestParameters.modelYearId}`;
			$service.get(apiUrl, onSucess, onError);
		};

		/**
		 * Method used to get the trims for a given vehicle.
		 * @param {Object} requestParameters
		 *   - {String} language
		 *   - {String} brand
		 *   - {String} nameplate
		 *   - {String} year
		 *   - {String} modelYear
		 * @param {Function} onSuccess - Success callback handler, should accept an object parameter
		 * @param {Function} onError - Error callback handler, should accept an object parameter - Optional
		 */
		$service.getTrims = (requestParameters, onSuccess, onError = null) => {
			const apiUrl = '/api/buildandprice/' + requestParameters.language +
				'/brand/' + requestParameters.brand +
				'/nameplate/' + requestParameters.nameplate +
				'/year/' + requestParameters.year +
				'/modelyear/' + requestParameters.modelYear +
				'/trims';
			$service.get(apiUrl, onSuccess, onError);
		};

		/**
		 * Method used to get the basic configuration for a vehicle.
		 * @param {An object containing: acode, language, packageCode, provinceCode, modelYearId, nameplateCode, year and brand} requestParameters
		 * @param {Object} requestParameters
		 *   - {String} acode
		 *   - {String} language
		 *   - {String} packageCode
		 *   - {String} provinceCode
		 *   - {String} modelYearId
		 *   - {String} nameplateCode
		 *   - {String} year
		 *   - {String} scratchSave
		 *   - {String} brand
		 * @param {Function} onSuccess - Success callback handler, should accept an object parameter
		 * @param {Function} onError - Error callback handler, should accept an object parameter - Optional
		 */
		$service.getConfig = (requestParameters, onSuccess, onError = null) => {
			let apiUrl = '/api/buildandprice/config';

			$service.post(apiUrl, requestParameters, onSuccess, onError);
		}

		$service.getTrimForAcode = (trimGroups, acode) => {
			acode = acode.toUpperCase();
			for (let i = 0; i < trimGroups.length; i++) {
				const trimGroup = trimGroups[i];
				for (let j = 0; j < trimGroup.trims.length; j++) {
					if (trimGroup.trims[j].acode === acode) {
						return trimGroup.trims[j];
					}
				}
			}
		}

		/**
		 * Returns a string with the parameters encoded and '&' joined.
		 * @param {Object} requestParameters
		 */
		$service.encodeQueryParameters = requestParameters => {
			return Object.entries(requestParameters)
				.map(([key, val]) => `${key}=${val}`)
				.join('&');
		}

		/**
		 * Saves the current car configuration to session storage.
		 * If there was an existing configuration we update it.
		 * @param {String} acode
		 * @param {String} brandCode
		 * @param {String} modelYearId
		 * @param {String} nameplateCode
		 * @param {String} packageCode
		 * @param {String} scratchSave
		 * @param {Object} trimGroup
		 * @param {String} year
		 */
		$service.saveConfigurationToSession = (
			acode,
			brandCode,
			modelYearId,
			nameplateCode,
			packageCode,
			scratchSave,
			trimGroup,
			year) => {

			const currentCarConfig = {
				packageCode,
				scratchSave,
				acode: acode,
				brandCode,
				nameplateCode,
				modelYearId,
				year
			};

			const configUrl =
				`build-and-price.configurations-` +
				`${brandCode}-` +
				`${nameplateCode}-` +
				`${year}-` +
				`${modelYearId}`;

			let savedConfigsForModelYear = JSON.parse(sessionStorage.getItem(configUrl));

			if (savedConfigsForModelYear) {
				const savedConfig = findSavedConfigForTrimGroup(savedConfigsForModelYear, trimGroup);
				if (savedConfig) {
					savedConfigsForModelYear[savedConfigsForModelYear.indexOf(savedConfig)] = currentCarConfig;
				} else {
					savedConfigsForModelYear.push(currentCarConfig);
				}
			} else {
				savedConfigsForModelYear = [currentCarConfig];
			}

			window.FcaCookieChecker.addSessionStorage(configUrl,
				JSON.stringify(savedConfigsForModelYear));

		}

		/**
		 * Checks for a saved configuration for the current vehicle.
		 * @param {String} brandCode
		 * @param {String} modelYearId
		 * @param {String} nameplateCode
		 * @param {Object} trimGroup
		 * @param {String} year
		 * Returns the saved configuration if it exists.
		 */
		$service.getSessionStoredConfiguration = (
			brandCode,
			modelYearId,
			nameplateCode,
			trimGroup,
			year) => {

				let savedConfigsForModelYear = JSON.parse(sessionStorage.getItem(
					`build-and-price.configurations-` +
					`${brandCode}-` +
					`${nameplateCode}-` +
					`${year}-` +
					`${modelYearId}`
				));

				if (savedConfigsForModelYear) {
					return findSavedConfigForTrimGroup(savedConfigsForModelYear, trimGroup);
				}
				return null;
			}

		const findSavedConfigForTrimGroup = (sessionConfigs, trimGroup) => {
			if (!sessionConfigs) {
				return null;
			}

			return sessionConfigs.find(config => {
				return trimGroup.trims.some(trim => {
					return trim.acode === config.acode;
				})
			})
		};

		const validateCachedResponse = cachedResponse => {
			if (!cachedResponse) return false;
			const dateDiff = cachedResponse.timeStamp - Date.now();
			return Math.round(((dateDiff % 86400000) % 3600000) / 60000) < 30;
		}
	}
})();
