/* eslint-disable indent */
(function () {
	angular
		.module('fca.buildAndPrice.stepsDirector')
		.service('trimService', TrimServiceSteps);

	function TrimServiceSteps($http, $timeout, $rootScope, $location, $q) {
		const REQUEST_DEBOUNCE_TIME = 1000;
		const language = FCA_SITES_CONFIG.language;

		var pricingUpdatePromise = null;

		const $service = this;

		$service.trimData = "";
		$service.pricingData = "";
		$service.pricingDataSingleModelYearId = [];
		$service.multipleModelYearPricingData = [];
		$service.modelYearIds = [];
		$service.dictionary = {};
		$service.provinceCode = null;

		$service.setTrimData = trimData => {
			$service.trimData = trimData;
		};

		$service.getTrimData = (language, brand, nameplate, year, modelYearId, provinceCode) => {
			// TODO: Fix this so we don't call trims 12 times on the trim group selector screen (for example when changing province)
			if ($service.trimData === "") {
				$service.trimData = {}; // make sure only one thread triggers the call

				const apiUrl = '/api/buildandprice/' + language + '/brand/' + brand +
					'/nameplate/' + nameplate + '/year/' + year +
					'/modelyear/' + modelYearId + '/trims';

				$http.get(apiUrl).then(response => {
					$service.trimData = response.data;
					$service.setDictionary(response.data);
					$service.broadcastTrimDataUpdated();
				});
			}
		};

		$service.getTrimPricing = (provinceCode, modelYearId, brand, nameplate, year) => {
			// Are we in iframe mode and Embedded inside a dealer site?
			let inIframeMode = $location.search()['view-name'] === 'headless_renderer';
			$service.pricingData = "";

			// make sure to clear our "cache" if the province code is different
			if ($service.provinceCode != provinceCode) {
				$service.modelYearIds = [];
				$service.provinceCode = provinceCode;
			}

			if ($service.modelYearIds.includes(modelYearId)) {
				return;
			}

			$service.modelYearIds.push(modelYearId);

			let apiUrl = '/api/buildandprice/'
					+ language + '/trims/prices?provinceCode='
					+ provinceCode
					+ '&modelYearId=' + modelYearId
					+ '&brand=' + brand
					+ '&nameplate=' + nameplate
					+ '&modelYear=' + year;

			storeAffiliateId();
			if (isAffiliateMode()) {
				apiUrl += getAffiliateIdUrlParam();
			}

			const campaignCode = sessionStorage.getItem('campaignCode');

			if (campaignCode && campaignCode !== 'null') {
				apiUrl += `&campaignCode=${campaignCode}`;
			}

			// Get hash parameters from url
			let hashParameters = getHashParameters();

			if (inIframeMode && !!hashParameters.dealerId) {
				// Dealer code parameter added to request if we are in iframe mode, it gives us the dealerStartingAtPrice
				apiUrl += `&dealerCode=${hashParameters.dealerId}`;
			}

			pricingUpdatePromise = $http.get(apiUrl).then(response => {
				$service.pricingData = response.data;

				if ($service.modelYearIds.length > 1) {
					$service.broadcastMultipleModelYearTrimPricingUpdated(modelYearId);
				} else {
					$service.broadcastTrimPricingUpdated(modelYearId);
				}

				$timeout(() => {
					pricingUpdatePromise = null
				}, REQUEST_DEBOUNCE_TIME);
			});
		};

		$service.setDictionary = data => {
			$service.dictionary = {
				drivetrain: data.drivetrains,
				boxLength: data.boxLengths,
				cabType: data.cabTypes,
				rearWheel: data.rearWheels,
				wheelbase: data.wheelbases,
				packages: data.packages
			};
		};

		$service.getDictionary = () => {
			return $service.dictionary;
		};

		$service.getDrivetrainDescription = code => {
			let drivetrain = "";

			if ($service.dictionary !== {}) {
				drivetrain = $service.getDescriptionFromListById(code, $service.dictionary.drivetrain);
			}

			return drivetrain;
		};

		$service.getBoxLengthDescription = code => {
			let boxLength = "";

			if ($service.dictionary !== {}) {
				boxLength = $service.getDescriptionFromListById(code, $service.dictionary.boxLength);
			}

			return boxLength;
		};

		$service.getCabTypeDescription = code => {
			let cabType = "";

			if ($service.dictionary !== {}) {
				cabType = $service.getDescriptionFromListById(code, $service.dictionary.cabType);
			}

			return cabType;
		};

		$service.getRearWheelDescription = code => {
			let rearWheel = "";

			if ($service.dictionary !== {}) {
				rearWheel = $service.getDescriptionFromListById(code, $service.dictionary.rearWheel);
			}

			return rearWheel;
		};

		$service.getWheelbaseDescription = code => {
			let wheelbase = "";

			if ($service.dictionary !== {}) {
				wheelbase = $service.getDescriptionFromListById(code, $service.dictionary.wheelbase);
			}

			return wheelbase;
		};

		$service.getDescriptionFromListById = (code, list) => {
			const item = list.find(item => code === item.id);

			return item ? item.description : "";
		};

		$service.broadcastTrimDataUpdated = () => {
			$rootScope.$broadcast('trim-service:trim-data-updated', { trimData: $service.trimData });
		};

		$service.broadcastTrimPricingUpdated = argModelYearId => {
			const modelYearId = argModelYearId.toString();
			if (!$service.pricingDataSingleModelYearId.find(e => e.modelYearId === modelYearId)) {
				$service.pricingDataSingleModelYearId.push({modelYearId: modelYearId, data: $service.pricingData});
			}
			$rootScope.$broadcast('trim-service:trim-pricing-update', { pricingData: $service.pricingDataSingleModelYearId });
		};

		$service.forceCacheReset = () => {
			// Used in 2021 VLP redesign "Build and Price" partial drawer
			// Forces the "cache" to reset when the drawer is opened more than once
			// Otherwise pricing does not show
			$service.provinceCode = null;
		};

		$service.broadcastMultipleModelYearTrimPricingUpdated = argModelYearId => {
			if ($service.pricingData === "") {
				return;
			}

			const modelYearId = argModelYearId.toString();

			if (!$service.multipleModelYearPricingData.find(e => e.modelYearId === modelYearId)) {
				$service.multipleModelYearPricingData.push({modelYearId: modelYearId, data: $service.pricingData});
			}

			$rootScope.$broadcast('trim-service:trim-pricing-update', { pricingData: $service.multipleModelYearPricingData });
		};
	}
})(angular);
