(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselColorizer', FcaSlickCarouselColorizer);

	function FcaSlickCarouselColorizer() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				slickTheme: '@',
				dynamicAnalytics: '<'
			},
			controllerAs: 'carousel',
			controller: FcaSlickCarouselColorizerController,
			link: FcaSlickCarouselColorizerLink
		};

		function FcaSlickCarouselColorizerController($scope, $element, $window, $timeout, gtmAnalytics, functionExecutionControl) {
			'ngInject';

			const $ctrl = this;

			$ctrl.carouselHasNextHiddenItem = true;
			$ctrl.carouselHasPrevHiddenItem = false;

			let scrollableWidth = 0;

			// Fix an issue when the slick carrousel forced aria-hidden to all elements even the current slide
			// TODO refactor the component to doesn't rely on slick carrousel
			$element.find('slick').on('init afterChange', function(event, slick, currentSlide) {
				if (currentSlide === undefined) {
					currentSlide = slick.currentSlide;
				}

				setTimeout(() => {
					slick.$slides.each((idx) => {
						slick.$slides.eq(idx).attr('aria-hidden', currentSlide !== idx).removeAttr('tabindex');
					});
				});
			});

			$ctrl.beforeChange = (event, slick, currentSlide, nextSlide) => {
				event.stopPropagation();
				let dataAttr = slick.$slides.eq(nextSlide).data();

				if (dataAttr && !$ctrl.dynamicAnalytics) {
					let category = dataAttr.analyticsCategory;
					let label = dataAttr.analyticsLabel;

					gtmAnalytics.trackEvent('event', {
						category: category,
						label: label
					});
				}
			};

			$ctrl.afterChange = (event, slick, currentSlide, nextSlide) => {
				event.stopPropagation();
			};

            $ctrl.$postLink = () => {
				$ctrl.colorsLoaded();

				$timeout(() => {
					angular.element($window).on('resize', functionExecutionControl.debounce(() => {
						$ctrl.colorsLoaded();
						$element.find('.customizer-preview slick').slick('setPosition');
					}, 500));
				});
			};

			$scope.$on('modelTray.init', () => {
				$ctrl.colorsLoaded();
			});


            $ctrl.colorsLoaded = () => {
				let customizerContainer = $element.find('.customizer-content')[0];
				let colorListContainer = $element.find('.customizer-content-container')[0];
				let colorListSelector = $element.find('.customizer-content-selector')[0];
				let colorList = $element.find('.customizer-content-selector.is-colorizer')[0];
				if ($(colorList).find('.customizer-content-selector-swatch-container')[0]) {
					let eachBoxWidth = $(colorList).find('.customizer-content-selector-swatch-container')[0].clientWidth;

					var boundingBoxWidth = customizerContainer.clientWidth - 90;

					if (boundingBoxWidth < (colorListSelector.clientWidth)) {
						var numberRoundDown = Math.floor((customizerContainer.clientWidth - 90) / eachBoxWidth);
						var calcWidth = numberRoundDown * eachBoxWidth;
						$ctrl.showArrow = true;
						$(colorListContainer).css('justify-content', 'flex-start');
						$(colorListContainer).css('margin', '0 auto');
						$(colorListContainer).css('width', calcWidth);


					} else {
						$(colorListContainer).css('justify-content', 'center');
						$ctrl.showArrow = false;
						$(colorListContainer).css('margin', '0 45px');
						$(colorListContainer).css('width', '');
					}
				}


				$ctrl.showArrow = (colorListContainer.clientWidth < colorListSelector.clientWidth)
            };

            $ctrl.arrowClicked = (direction) => {
				let colorList = $element.find('.customizer-content-container');
				let colorSwatch = $(colorList).find('.customizer-content-selector-swatch-container')[0].clientWidth;
				let selectorList = $element.find('.customizer-content-selector')[0].clientWidth;

				if (direction === 'left') {
					scrollableWidth = Math.max(0, scrollableWidth - colorSwatch);
					colorList.animate({scrollLeft: scrollableWidth}, 200);
					$ctrl.carouselHasNextHiddenItem = true;
					$ctrl.carouselHasPrevHiddenItem = scrollableWidth > 0;
				} else {
					scrollableWidth = scrollableWidth + colorSwatch;
					colorList.animate({scrollLeft: scrollableWidth}, 200);
					$ctrl.carouselHasPrevHiddenItem = true;
					$ctrl.carouselHasNextHiddenItem = (scrollableWidth + colorList[0].clientWidth) < selectorList;
				}
			};
		}

		/*
		 * Adds a css class to allow for custom slick carousel styles
		 */
		function FcaSlickCarouselColorizerLink(scope, element, attrs, $ctrl) {
			element.addClass($ctrl.slickTheme);
		}
	}
})();
