(function() {
	angular
		.module('fca.brandApp')
		.service('miniNavService', MiniNavService);

	function MiniNavService() {
		let activeItem = '';

		this.itemCollection = [];

		this.setActiveItem = (groupId) => {
			activeItem = groupId;
		};

		this.getActiveItem = () => {
			return activeItem;
		};
	}
})();
