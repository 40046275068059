/* eslint-disable indent */
(function () {
    angular.module('fca.buildAndPrice.nameplatePricingSingle').component('nameplatePricingSingle', {
        templateUrl: '/build-and-price/nameplate-pricing-single/nameplate-pricing-single.html',
        controller: NameplatePricingSingle,
        controllerAs: '$ctrl',
        bindings: {
            priceObjectType: '@',
            priceObject: '<',
            disclaimerFinance: '@',
            disclaimerLease: '@',
            uniqueId: '@'
        }
    });

    function NameplatePricingSingle() {
      'ngInject';

      const $ctrl = this;

      $ctrl.language = window.FCA_SITES_CONFIG.language;
      $ctrl.affiliateMode = false;
      $ctrl.brandCode = FCA_SITES_CONFIG.name;

      $ctrl.$onInit = () => {
        if ($ctrl.priceObjectType === 'lease' || $ctrl.priceObjectType === 'altLease' ) {
          $ctrl.disclaimer = $ctrl.disclaimerLease;
        } else {
          $ctrl.disclaimer = $ctrl.disclaimerFinance;
        }

        storeAffiliateId();
        $ctrl.affiliateMode = isAffiliateMode();
      }
    }
})();
