/* eslint-disable indent */
(function () {
    angular.module('fca.buildAndPrice.nameplatePricingOr').component('nameplatePricingOr', {
        templateUrl: '/build-and-price/nameplate-pricing-or/nameplate-pricing-or.html',
        controller: NameplatePricingOr,
        controllerAs: '$ctrl',
        bindings: {
            setActiveTab: '&',
            priceObjectType: '@',
            priceObjectAltType: '@',
            activeTab: '@',
            priceObject: '<',
            disclaimerFinance: '@',
            disclaimerLease: '@',
            uniqueId: '@'
        }
    });

    function NameplatePricingOr($translate, $filter) {
      'ngInject';

      const $ctrl = this;

      $ctrl.language = window.FCA_SITES_CONFIG.language;
      storeAffiliateId();
      $ctrl.affiliateMode = isAffiliateMode();
      $ctrl.brandCode = FCA_SITES_CONFIG.name;

      $ctrl.$onInit = () => {
        if ($ctrl.priceObjectType === 'lease' || $ctrl.priceObjectType === 'altLease' ) {
          $ctrl.disclaimer = $ctrl.disclaimerLease;
        } else {
          $ctrl.disclaimer = $ctrl.disclaimerFinance;
        }
      };

      $ctrl.$postLink = () => {
        document.addEventListener('keypress', function logKey(e) {
          if ( e.code === 'Enter' && $(":focus").hasClass('price-display-or-element') ) {
            $(":focus").click();
          }
        });
      }

      $ctrl.generateBestPaymentAriaLabel = () => {
        const priceData = $ctrl.priceObject[$ctrl.priceObjectType];

        const bestPaymentLabel = $translate.instant('build-and-price.common.best-payment');
        const paymentAmount =  $filter('htmlToPlainText')($filter('fcaCurrencyWithDollarSign')($filter('fcaRoundup')(priceData.payment)));
        const paymentFrequency = $translate.instant($filter('fcaFrequency')(priceData.frequency));
        const paymentRate = $filter('fcaRate')(priceData.rate) + '%';
        const paymentTerm = priceData.term + $translate.instant('menu.vehicle.month');

        return `${bestPaymentLabel}  ${paymentAmount}/${paymentFrequency}@${paymentRate}/${paymentTerm}`;
      }

      $ctrl.generateBestRateAriaLabel = () => {
        const priceAltData = $ctrl.priceObject[$ctrl.priceObjectAltType];

        const bestRateLabel = $translate.instant('build-and-price.common.best-rate');
        const priceRate = $filter('fcaRate')(priceAltData.rate) + '%';
        const priceTerm = priceAltData.term + $translate.instant('menu.vehicle.month')

        return `${bestRateLabel} ${priceRate}/${priceTerm}`;
      }
    }
})();
