/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.stepsDirector')
        .service('configService', ConfigService);

    function ConfigService($http, $rootScope, $window, $location, trimService, userAccountService) {
        'ngInject';
        let $service = this;

        // Are we in iframe mode and Embedded inside a dealer site?
        $service.inIframeMode = $location.search()['view-name'] === 'headless_renderer';
        $service.hashParameters = getHashParameters();

        $service.acode = "";
        $service.language = "";
        $service.modelYearId = "";
        $service.nameplateCode = "";
        $service.year = "";
        $service.brandCode = "";
        $service.subBrandCode = "";
        $service.provinceCode = "";
        $service.scratchSave = "";
        $service.previousScratchSave = "";
        $service.showAlerts = true;
        // categoriesObjects contain all selected options filtered by category
        $service.categoriesObjects = {};
        $service.selectedTrim = {};
        $service.selectedPackage = null;
        $service.builtVehicleSaved = null;
        $service.jellyUrl = null;
        $service.jellyType = null;
        $service.nameplateName = null;
        $service.trimName = null;
        $service.paintCode = null;
        $service.driveTrain = null;
        // configuration listeners
        $service.configurationListeners = [];
        $service.irisJellyListeners = [];
        $service.legacyJellyListeners = [];
        $service.colorCodeListeners = [];
        $service.trimGroupListeners = [];

        $service.categoriesMap = {
            enginePackage: 'enginePackage',
            exteriorColor: 'exteriorColor',
            interiorColor: 'interiorColor',
            wheel: 'wheel',
            entertainmentTechnology: 'entertainmentTechnology',
            exterior: 'exterior',
            interior: 'interior',
            mechanical: 'mechanical',
            group: 'group',
            roofColor: 'roofColor',
            safetySecurity: 'safetySecurity',
            seatColor: 'seatColor',
        };

        $service.initializeConfig = (acode, language, modelYearId, nameplateCode, year, brandCode, subBrandCode, provinceCode, longitude, latitude) => {
            $service.acode = acode;
            $service.language = language;
            $service.modelYearId = modelYearId;
            $service.nameplateCode = nameplateCode;
            $service.year = year;
            $service.brandCode = brandCode;
            $service.subBrandCode = subBrandCode;
            $service.provinceCode = provinceCode;
            $service.longitude = longitude;
            $service.latitude = latitude;
            $service.hashParameters = getHashParameters();
            if ($service.hashParameters.scratchsave) {
                $service.setScratchSave(LZString.decompressFromEncodedURIComponent($service.hashParameters.scratchsave));
                $rootScope.$broadcast('scratch-save-updated:scratch-save', { scratch: $service.getScratchSave() });
            }
            $service.saveConfigurationToSession();

            let storageValue = $window.localStorage.getItem("build-and-price-alerts");
            if (storageValue !== null) {
                $service.setShowAlerts(storageValue === 'show');
            } else {
                $service.setShowAlerts(true);
            }
            const swatchesUrl = `/data/buildandprice/en/` +
                `brand/${$service.subBrandCode}/` +
                `nameplate/${$service.nameplateCode}/` +
                `year/${$service.year}/` +
                `modelyear/${$service.modelYearId}/swatches`;

            $http.get(swatchesUrl).then(response => {
                $service.swatchesData = response.data;
                $rootScope.$broadcast('paint-chips:swatches-data-updated', {swatchesData: $service.swatchesData});
            }).catch(function (e) {
                throw new Error('error retrieving color swatches', e);
            });

            if ($service.longitude && $service.latitude && $service.nameplateCode !== '') {
                $service.getPopularOptions($service.longitude, $service.latitude, $service.nameplateCode);
            }

        };

        $service.getSessionStoredConfiguration = () => {
            let savedConfigsForModelYear = JSON.parse(sessionStorage.getItem(
                `build-and-price.configurations-` +
                `${$service.subBrandCode}-` +
                `${$service.nameplateCode}-` +
                `${$service.year}-` +
                `${$service.modelYearId}`
            ));

            const trimGroup = $service.getTrimGroupForAcode($service.acode);
            if (trimGroup) {
                $service.currentTrimgroup = trimGroup;
            }

            if (savedConfigsForModelYear) {
                return $service.findSavedConfigForTrimGroup(savedConfigsForModelYear);
            }
            return null;
        };

        $service.saveConfigurationToSession = () => {
            if (!$service.categoriesObjects
                || !$service.selectedPackage
                || !$service.selectedTrim
                || !$service.currentTrimgroup) {
                return;
            }

            const currentCarConfig = {
                package: $service.selectedPackage.code,
                acode: $service.acode,
                trim: $service.selectedTrim,
                scratchSave: $service.scratchSave,
                modelYearId: $service.modelYearId,
                nameplateCode: $service.nameplateCode,
                year: $service.year,
                brand: $service.brandCode,
                subBrand: $service.subBrandCode,
                updatedDate: new Date()
            };

            $service.notifyConfigurationListeners(currentCarConfig);

            let savedConfigsForModelYear = JSON.parse(sessionStorage.getItem(
                `build-and-price.configurations-` +
                `${$service.subBrandCode}-` +
                `${$service.nameplateCode}-` +
                `${$service.year}-` +
                `${$service.modelYearId}`
            ));

            if (savedConfigsForModelYear) {
                const savedConfig = $service.findSavedConfigForTrimGroup(savedConfigsForModelYear);
                if (savedConfig) {
                    savedConfigsForModelYear[savedConfigsForModelYear.indexOf(savedConfig)] = currentCarConfig;
                } else {
                    savedConfigsForModelYear.push(currentCarConfig);
                }
            } else {
                savedConfigsForModelYear = [currentCarConfig];
            }

            window.FcaCookieChecker.addSessionStorage(
                `build-and-price.configurations-` +
                `${$service.subBrandCode}-` +
                `${$service.nameplateCode}-` +
                `${$service.year}-` +
                `${$service.modelYearId}`,
                JSON.stringify(savedConfigsForModelYear));
        };

        // If the current modelYear has saved configurations in session storage,
        // we must check against the trims of the current trim group.
        // If we find a matching acode it means the current trim group
        // has a saved configuration.
        $service.findSavedConfigForTrimGroup = (sessionConfigs) => {
            if (!sessionConfigs || !$service.currentTrimgroup || !$service.currentTrimgroup.trims) {
                return null;
            }

            return sessionConfigs.find(config => {
                return $service.currentTrimgroup.trims.some(trim => {
                    return trim.acode === config.acode;
                })
            })
        };

        $service.getTrimGroupForAcode = (acode) => {
            if (!trimService.trimData || !trimService.trimData.trimGroups) {
                return;
            }
            const trimGroups = trimService.trimData.trimGroups;
            for (let i = 0; i < trimGroups.length; i++) {
                const trimGroup = trimGroups[i];
                for (let j = 0; j < trimGroup.trims.length; j++) {
                    if (trimGroup.trims[j].acode === acode) {
                        return trimGroup;
                    }
                }
            }
            return null;
        };

        $service.selectOption = (option, scratchSave = $service.scratchSave, status = "COMPLETE", showAlert = true) => {
            let apiUrl = '/api/buildandprice/select-option';

            let postData = {
                acode: $service.acode,
                language: $service.language,
                modelYearId: $service.modelYearId,
                nameplateCode: $service.nameplateCode,
                year: $service.year,
                brand: $service.brandCode,
                subBrand: $service.subBrandCode,
                provinceCode: $service.provinceCode,
                packageCode: $service.selectedPackage.code,
                optionCode: option.code,
                scratchSave: scratchSave
            };

            storeAffiliateId();
            const affiliateId = getStoredAffiliateId();
            if (isAffiliateMode() && affiliateId) {
                postData[affiliateIdName] = affiliateId;
            }
            const campaignCode = sessionStorage.getItem('campaignCode');
            if (campaignCode && campaignCode !== 'null') {
                postData['campaignCode'] = campaignCode;
            }

            if ($service.inIframeMode && !!$service.hashParameters.dealerId) {
                // Dealer code parameter added to request if we are in iframe mode, it gives us the admin fee attribute on the config call
                postData['dealerCode'] = $service.hashParameters.dealerId;
            }

            if (status === "MUSTHAVE") {
                postData.previousState = $service.previousScratchSave;
            }

            //Keeping the previousScratchSave is required to properly handle the MUSTHAVE cases
            $service.previousScratchSave = scratchSave;

            $http.post(apiUrl, postData).then(response => {
                $service.broadcastToAlertWindow(response.data, option,
                    showAlert);
            }).catch(function (e) {
                $rootScope.$broadcast('options-selector:show-missed-alert', {});
                throw new Error('error selecting option', e);
            });
        };

        // On select package update reset all selected options
        $service.clearOptionCategory = (key) => {
            $service.categoriesObjects[key] = [];
        };

        $service.parseConfiguration = (config) => {
            $service.pricing = config.pricing;
            $rootScope.$broadcast('calculator:set-programs');
            Object.keys(config).filter(value => {
                return config.hasOwnProperty(value);
            }).forEach((key) => {
                if ($service.categoriesMap[key] !== undefined) {
                    $service.parseCategory(config, key);
                }
            });
            $service.saveConfigurationToSession();
        };

        $service.parseCategory = (config, category) => {
            $rootScope.$broadcast('trim-selector:options-' + category + '-updated', { options: config[category] });
             // Flattened array of all options in that category
            let newOptions = [];
            config[category]['categories'].forEach(optionCategory => {
                optionCategory.options.forEach(option => {
                    newOptions.push(option);
                })
            });

            // remove any option that's not in that category anymore
            if ($service.categoriesObjects[category] != null) {
                $service.categoriesObjects[category] = $service.categoriesObjects[category].filter(currentOption => {
                    return newOptions.find( newOption => newOption.code === currentOption.code ) !== undefined;
                });
            }

            config[category]['categories'].forEach(element => {
                element.options.forEach(option => {
                    option.selected = $service.checkIfOptionShouldBeSelected(option);
                    $service.handleOptionSelected({ category: category, option: option });
                })
            });
        };

        $service.checkIfOptionShouldBeSelected = (option) => {
            return option.state === "S" || option.state === "G"
        };

        $service.handleOptionSelected = (optionObject) => {
            if ($service.categoriesObjects[optionObject.category] !== undefined) {
                let savedOptionIndex = $service.findOptionIndex(optionObject);
                if (savedOptionIndex !== -1 && !optionObject.option.selected) {
                    $service.categoriesObjects[optionObject.category].splice(savedOptionIndex, 1);
                } else if (savedOptionIndex === -1 && optionObject.option.selected) {
                    $service.categoriesObjects[optionObject.category].push(optionObject.option);
                }
            } else if (optionObject.option.selected) {
                $service.categoriesObjects[optionObject.category] = [optionObject.option];
            }
        };

        $service.findOptionIndex = (optionObject) => {
            let result = -1;
            $service.categoriesObjects[optionObject.category].forEach((element, index) => {
                if (optionObject.option.code === element.code) {
                    result = index;
                    return;
                }
            });
            return result;
        };

        $service.updateSelectedTrim = (newTrim) => {
            $service.selectedTrim = newTrim;
            const trimGroup = $service.getTrimGroupForAcode($service.acode);
            if (!trimGroup) {
                return;
            }
            $service.currentTrimgroup = trimGroup.code;
            $service.notifyTrimGroupListeners(trimGroup.code);
        };

        $service.updateSelectedPackage = (newPackage) => {
            $service.selectedPackage = newPackage;
            $service.broadcastToTrimUpsell();
        };

        $service.updateCalculatorParameters = () => {
            $rootScope.$broadcast('options-selector:reset-calculator-parameters', {
                packageAndOptionsCode: $service.extractPackageAndOptionsCode(),
                selectedPackageCode: $service.selectedPackage.code,
                acode: $service.acode,
                modelYearId: $service.modelYearId,
                year: $service.year,
                nameplateCode: $service.nameplateCode,
                brandCode: $service.brandCode,
                subBrandCode: $service.subBrandCode,
                trimName: $service.selectedTrim.trimName,
                scratchSave: $service.scratchSave
            });
        };

        $service.getPopularOptions = (longitude, latitude, nameplateCode) => {
            const popularOptionsUrl =
                `/data/inventories/popularOptions/${nameplateCode}`+
                `?latitude=${latitude}&`+
                `longitude=${longitude}`;
            $http.get(popularOptionsUrl).then(response => {
                $service.popularOptions = response.data;
            }).catch(exception => {
                console.trace('%cError retrieving popular options!', 'color:red;', exception);
            });
        };

        $service.getSelectedTrim = () => {
            return $service.selectedTrim;
        };

        $service.getSelectedPackage = () => {
            return $service.selectedPackage;
        };

        $service.getCategoriesObjects = () => {
            return $service.categoriesObjects;
        };

        $service.broadcastToAlertWindow = (data, option, showAlert) => {
            $rootScope.$broadcast('options-selector:select-options', { optionResult: data, selectedOption: option, showAlert: showAlert });
        };

        $service.broadcastToTrimUpsell = () => {
            $rootScope.$broadcast('trim-upsell:configuration-updated', {
                acode : $service.acode,
                provinceCode : $service.provinceCode,
                paint : $service.paintCode,
                package : $service.selectedPackage,
                scratchSave : $service.scratchSave,
                driveTrain : $service.driveTrain
            });
        };

        $service.setAcode = (acode) => {
            $service.acode = acode;
        };

        $service.getAcode = () => {
            return $service.acode;
        };

        $service.setLanguage = (language) => {
            $service.language = language;
        };

        $service.getLanguage = () => {
            return $service.language;
        };

        $service.setScratchSave = (scratchSave) => {
            $service.scratchSave = scratchSave;
        };

        $service.getScratchSave = () => {
            return $service.scratchSave;
        };

        $service.setShowAlerts = (showAlerts) => {
            $service.showAlerts = showAlerts;
        };

        $service.getShowAlerts = () => {
            return $service.showAlerts;
        };

        $service.toggleShowAlerts = () => {
            let storageValue = $window.localStorage.getItem("build-and-price-alerts");
            if (storageValue === 'hidden') {
                $window.FcaCookieChecker.addLocalStorage("build-and-price-alerts", 'show', window.FcaCookieChecker.cookieGroups.FUNCTIONNAL_COOKIES);
                $service.setShowAlerts(true);
            } else {
                $window.FcaCookieChecker.addLocalStorage("build-and-price-alerts", 'hidden', window.FcaCookieChecker.cookieGroups.FUNCTIONNAL_COOKIES);
                $service.setShowAlerts(false);
            }
        };

        $service.saveLegacyJelly = (jellyUrl) => {
            $service.jellyUrl = jellyUrl;
            $service.jellyType = 'legacy';
            $service.notifyLegacyJellyListeners(jellyUrl);
        };

        $service.saveIrisJelly = (jellyUrl) => {
            $service.jellyUrl = jellyUrl;
            $service.jellyType = 'iris';
            $service.notifyIrisJellyListeners(jellyUrl);
        };

        $service.saveTrimGroupSlingCode = (trimGroupSlingCode) => {
            $service.trimGroupSlingCode = trimGroupSlingCode;
        };

        $service.setPaintCode = (paintCode) => {
            $service.paintCode = paintCode;
            $service.notifyColorCodeListeners(paintCode);
        };

        $service.setPaymentType = (paymentType) => {
            $service.paymentType = paymentType;
        };

        $service.setNbOfPaymentPerYear = (nbOfPaymentPerYear) => {
            $service.nbOfPaymentPerYear = nbOfPaymentPerYear;
        };

        $service.setPaymentFrequency = (paymentFrequency) => {
            $service.paymentFrequency = paymentFrequency;
        };

        $service.setDefaultFinancePayment = (defaultFinancePayment) => {
            $service.defaultFinancePayment = defaultFinancePayment;
        };

        $service.setDefaultLeasePayment = (defaultLeasePayment) => {
            $service.defaultLeasePayment = defaultLeasePayment;
        };

        $service.setDefaultNetAmount = (defaultNetAmount) => {
            $service.defaultNetAmount = defaultNetAmount;
        };

        $service.setDriveTrain = (driveTrain) => {
            $service.driveTrain = driveTrain;
        };

        $service.setDealer = (dealer) => {
            $service.dealer = dealer;
        };

        $service.setPaymentInfo = (paymentInfo) => {
            $service.paymentInfo = paymentInfo;
        };

        $service.getPaymentInfo = () => {
            return $service.paymentInfo;
        };

        /**
         * Save the current configuration for the connected user
         * @param controllerScope
         * @param pricingInfo
         */
        $service.saveConfiguration = (controllerScope, pricingInfo) => {
            if (userAccountService.isLibraryAvailable()) {
                if (UA.isUserLoggedIn()) {
                    if ($service.builtVehicleSaved) {
						UA.removeBuiltVehicle($service.builtVehicleSaved.id).then(
							() => controllerScope.$apply(() => $service.resetSavedConfiguration()));
                    } else {
                        let vehicleData = {
                            acode: $service.acode,
                            language: $service.language,
                            provinceCode: $service.provinceCode,
                            brandCode: $service.brandCode,
                            subBrandCode: $service.subBrandCode,
                            modelYearId: $service.modelYearId,
                            nameplateCode: $service.nameplateCode,
                            year: $service.year,
                            packageCode: $service.selectedPackage.code,
                            scratchsave: $service.scratchSave,
                            packageAndOptionsCode: $service.extractPackageAndOptionsCode(),
                            jellyUrl: $service.jellyUrl,
                            jellyType: $service.jellyType,
                            mfgCode: $service.selectedTrim.mfgCode,
                            paintCode: $service.paintCode,
                            trimGroupSlingCode: $service.trimGroupSlingCode,
                            pricingInfo: {
                                netAmount: this.convertPriceInNumber(pricingInfo.netAmount),
                                financeTerm: this.convertPriceInNumber(pricingInfo.financeTerm),
                                financeRate: this.convertPriceInNumber(pricingInfo.financeRate),
                                financePayment: this.convertPriceInNumber(pricingInfo.financePayment),
                                financePaymentFrequency: pricingInfo.financePaymentFrequency,
                                leaseTerm: this.convertPriceInNumber(pricingInfo.leaseTerm),
                                leaseRate: this.convertPriceInNumber(pricingInfo.leaseRate),
                                leasePayment: this.convertPriceInNumber(pricingInfo.leasePayment),
                                leasePaymentFrequency: pricingInfo.leasePaymentFrequency
                            }
                        }

						UA.addBuiltVehicle(vehicleData)
							.then((response) => controllerScope.$apply(() => $service.builtVehicleSaved = response));

                    }
                } else {
                    $rootScope.callbackParameters = {
                        acode: $service.acode,
                        language: $service.language,
                        provinceCode: $service.provinceCode,
                        brandCode: $service.brandCode,
                        subBrandCode: $service.subBrandCode,
                        modelYearId: $service.modelYearId,
                        nameplateCode: $service.nameplateCode,
                        year: $service.year,
                        packageCode: $service.selectedPackage.code,
                        scratchsave: $service.scratchSave,
                        packageAndOptionsCode: $service.extractPackageAndOptionsCode(),
                        jellyUrl: $service.jellyUrl,
                        pricingInfo: {
                            netAmount: this.convertPriceInNumber(pricingInfo.netAmount),
                            financeTerm: this.convertPriceInNumber(pricingInfo.financeTerm),
                            financeRate: this.convertPriceInNumber(pricingInfo.financeRate),
                            financePayment: this.convertPriceInNumber(pricingInfo.financePayment),
                            financePaymentFrequency: pricingInfo.financePaymentFrequency,
                            leaseTerm: this.convertPriceInNumber(pricingInfo.leaseTerm),
                            leaseRate: this.convertPriceInNumber(pricingInfo.leaseRate),
                            leasePayment: this.convertPriceInNumber(pricingInfo.leasePayment),
                            leasePaymentFrequency: pricingInfo.leasePaymentFrequency
                        }
                    };
                }
            }
        };

        /**
         * Reset and remove hash parameter builtVehicleId from url
         */
        $service.resetSavedConfiguration = () => {
            $service.builtVehicleSaved = null;

            //remove hash parameter builtVehicleId from url
            //$location.hash($location.hash().replace(/(&)*builtVehicleId=(\d)+(&)*/, ''));
        };

        $service.extractPackageAndOptionsCode = () => {
            let result = [];
            let categoryKeys = Object.keys($service.categoriesObjects);
            for (let i = 0; categoryKeys.length > i; i++) {
                for (let x = 0; $service.categoriesObjects[categoryKeys[i]].length > x; x++) {
                    result.push($service.categoriesObjects[categoryKeys[i]][x].code);
                }
            }
            return result;
        };

        this.convertPriceInNumber = (value) => {
            let result = value;
            if (value && typeof(value) === 'string') {
                result = parseFloat(value.replace(/[,$]/g, ''));
            }
            return result;
        };

        /**
         * Utility method to call all listeners in an array
         */
        $service.notifyListeners = (listeners, data) => {
            if (listeners && listeners.length > 0) {

                // call all listeners
                listeners.forEach(function(listener) {
                    if (listener) {
                        try {
                            listener(data);
                        } catch(exception) {
                            console.error("error calling listener: ", listener, data);
                        }
                    }
                });
            }
        };

        $service.registerConfigurationListener = (configurationListener) => {
            $service.configurationListeners.push(configurationListener);
        };

        $service.notifyConfigurationListeners = (updatedConfiguration) => {
            $service.notifyListeners($service.configurationListeners, updatedConfiguration);
        };

        $service.registerLegacyJellyListener = (legacyJellyListener) => {
            $service.legacyJellyListeners.push(legacyJellyListener);
        };

        $service.notifyLegacyJellyListeners = (legacyJellyUrl) => {
            $service.notifyListeners($service.legacyJellyListeners, legacyJellyUrl);
        };

        $service.registerIrisJellyListener = (irisJellyListener) => {
            $service.irisJellyListeners.push(irisJellyListener);
        };

        $service.notifyIrisJellyListeners = (irisJellyUrl) => {
            $service.notifyListeners($service.irisJellyListeners, irisJellyUrl);
        };

        $service.registerColorCodeListeners = (colorCodeListener) => {
            $service.colorCodeListeners.push(colorCodeListener);
        };

        $service.notifyColorCodeListeners = (colorCode) => {
            $service.notifyListeners($service.colorCodeListeners, colorCode);
        };

        $service.registerTrimGroupListeners = (trimGroupListener) => {
            $service.trimGroupListeners.push(trimGroupListener);
        };

        $service.notifyTrimGroupListeners = (trimGroup) => {
            $service.notifyListeners($service.trimGroupListeners, trimGroup);
        };

        $service.setMissingWheelUrl = url => {
            $service.missingWheelUrl = url;
        }

        $service.getMissingWheelUrl = () => {
            return $service.missingWheelUrl;
        }

        $service.setProvinceCode = province => {
            $service.provinceCode = province;
        }

        $service.getWheelCodeOverride = wheelOption => {
            if (!$service.swatchesData) {
                return wheelOption.code.substr(0,3);
            }
            const category = findCategory(wheelOption);
            if (!category) {
                return wheelOption.code.substr(0,3);
            }
            const swatchOption = findOption(category, wheelOption.code);
            if (!swatchOption) {
                return wheelOption.code.substr(0,3);
            }
            return swatchOption.codeOverride ? swatchOption.codeOverride.join(',') : wheelOption.code.substr(0,3);
        }

        const findCategory = (option) => {
            let result = undefined;
            $service.swatchesData.forEach(element => {
                if (element.eccCode === option.ecc) {
                    result = element;
                }
            });
            return result;
        }

        const findOption = (category, code) => {
            let result = undefined;
            category.options.forEach(option => {
                if (option.code === code) {
                    result = option;
                }
            });
            return result;
        }
    }
})(angular);
